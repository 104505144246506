"use client";

import style from "./style.module.scss";

// ------------引入swiper------------
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore } from "swiper/types";
import "swiper/css";
import Prev from "@/assets/icons/prev.svg";
import Next from "@/assets/icons/next.svg";

import SafeLink from "@/components/SafeLink";
import { useRef, useState } from "react";
import Image from "next/image";

export default function Module({ moduleData }: { moduleData: any }) {
  const [isDataReady, setIsDataReady] = useState(false);
  const swiperRef = useRef<SwiperCore>();

  const initConfig = (data: any) => {
    const config = {
      data: data.dataBatch?.["category-list"] || [],
      title: data.dataBatch?.title[0].title || "",
    };
    return config;
  };

  const pageConfig = initConfig(moduleData);
  if (pageConfig.data.length < 5) return null;

  return (
    <div className={style.bg}>
      <div className={style["uk-pc-popular-categories"]}>
        <div className={style.title}>{pageConfig.title}</div>
        <Swiper
          slidesPerView={5}
          spaceBetween={-10}
          loop={true}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className={style.mySwiper}
          style={{
            visibility: isDataReady ? "visible" : "hidden",
          }}
          onSwiper={() => {
            setIsDataReady(true);
          }}
        >
          {pageConfig.data.map((category: any, index: any) => (
            <SwiperSlide key={index}>
              <div className={style.slideItem}>
                <SafeLink href={`/cms/${category?.jump?.pageCode}`} target="_blank">
                  <img
                    loading="lazy"
                    src={category?.category_image?.imageUrl}
                    width={category?.category_image?.imageWidth}
                    height={category?.category_image?.imageHeight}
                    alt={category?.category_name}
                    className={style.cateimg}
                  />
                  <p>{category?.category_name}</p>
                </SafeLink>
              </div>
            </SwiperSlide>
          ))}
          {pageConfig.data.length > 5 && (
            <div className={style.buttonGroup}>
              <button className={style.prev} onClick={() => swiperRef.current?.slidePrev()}>
                <Prev />
              </button>
              <button className={style.next} onClick={() => swiperRef.current?.slideNext()}>
                <Next />
              </button>
            </div>
          )}
        </Swiper>
      </div>
    </div>
  );
}
