"use client";
import style from "./style.module.scss";
import { queryNewcomerApi, isNewcomerApi } from "./src/api/index";
import { useEffect, useState } from "react";
import ProductList from "./src/components/productList";
import ChevronDown from "./src/components/chevronDown";
import UKToast from "@/components/UKToast";
import { UKPageLoading, UKPageNothing } from "@/common-components-src/js/main";
import { sendPv } from "@/tracks/25159";

export default function Module({ moduleData }: { moduleData: any }) {
  const [cuPage, setCuPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [activityId, setActivityId] = useState<number>(0);

  const [products, setProducts] = useState<any>(null);
  const [loadingState, setLoadingState] = useState<string>("loading"); // 控制底部显示加载状态 loaded  loading  noMore
  const [isNewcomerFlag, setIsNewcomerFlag] = useState<boolean>(false);
  const [loading, setLoading] = useState(true); //是否在加载数据

  useEffect(() => {
    setLoadingState("loading");
    initConfig(moduleData);
  }, []);
  useEffect(() => {
    isNewcomer();
  }, []);

  sendPv.Activity_Discount();

  const initConfig = (Data: any) => {
    if (Data && Object.keys(Data).length > 0) {
      const id = JSON.parse(Data.externalParams)?.inputsValue?.activityId;
      setActivityId(id);
      queryNewcomerApi({
        cuPage,
        pageSize,
        activityId: id,
        scrollId: null,
      }).then((res) => {
        if (res && res.data.length > 0) {
          const { data, total } = res;
          const _list = data;
          console.log(_list.length, "_listLength");

          if (total !== 0) {
            console.log("_list.length", _list.length, total);
            if (_list.length < pageSize) {
              setLoadingState("noMore");
            } else {
              setLoadingState("loaded");
            }
          } else {
            setLoadingState("noMore");
          }
          setProducts(_list);
          setLoading(false);
        }
      });
    }
  };

  // 判断是否是新人
  const isNewcomer = () => {
    isNewcomerApi()
      .then((res) => {
        if (res && res.IDENTITY_NEWCOMER) {
          // 0-老用户  1-是新人
          if (res.IDENTITY_NEWCOMER == 0) {
            UKToast.show({
              content: "Sorry, for new users only",
              icon: "warning",
            });
            setIsNewcomerFlag(false);
          } else if (res.IDENTITY_NEWCOMER == 1) {
            setIsNewcomerFlag(true);
          }
        }
      })
      .catch((err) => {
        console.log("err.msg:", err);
      });
  };

  // 点击查看更多
  const ViewMoreClick = async () => {
    //最后一页了 不需要加载更多
    if (loadingState == "noMore") {
      return;
    }
    const scrollId = products.length ? products[products.length - 1].extInfo.scrollId : null;
    const page = cuPage + 1;
    setCuPage(page);
    setLoadingState("loading");
    queryProductFeedsFn({
      cuPage: page,
      pageSize,
      activityId: activityId,
      scrollId: scrollId,
    });
  };

  // 获取秒杀列表数据
  const queryProductFeedsFn = (props: { cuPage: number; pageSize: number; activityId?: number; scrollId?: any }) => {
    queryNewcomerApi({
      ...props,
    }).then((res: any) => {
      if (res && res.data?.length > 0) {
        const _list = [...products, ...(res.data || [])];
        console.log("zsf _list.length < res.total", _list.length, res.total);
        if (_list.length < res.total) {
          setLoadingState("loaded");
        } else {
          setLoadingState("noMore");
        }
        setProducts(_list);
      } else {
        setLoadingState("noMore");
      }
    });
  };

  return (
    <div className={style["uk-pc-newcomer-land"]}>
      {/* 主要内容 */}
      <div className={style.content}>
        <div className={style.content_wrap}>
          {loading ? (
            <div className={style.loading_wrap}>
              <UKPageLoading text="Loading..."></UKPageLoading>
            </div>
          ) : (
            <>
              {products && products.length > 0 ? (
                <div className={style.content}>
                  {/* 主要内容 */}
                  <ProductList productList={products} isNewcomerFlag={isNewcomerFlag}></ProductList>
                  {/* 加载更多 */}
                  <ChevronDown ViewMoreClick={ViewMoreClick} loadingState={loadingState}></ChevronDown>
                </div>
              ) : (
                <div className={style.errorBox}>
                  {/* <img className={style.emptyImg} src="" alt="" /> */}
                  <div className={style.emptyTxt}>
                    <UKPageNothing text="There's nothing more" />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
