import { jdiColorFetch } from '../../../../../../apis/colorFetchV2';
import { cmsConfig as CONFIG } from "../../../../common/cmsConfig";

/**
* 获取热销数据
* @param params 
* @returns 
*/
export const queryProductFeeds = (params: { cuPage: number, pageSize: number, batchId?: number, lastSkuId?: string | null, }): Promise<any> => {

    // batchId, lastSkuId, this.cuPage, 10
    const { cuPage, pageSize, batchId, lastSkuId } = params
    const options: any = { cuPage, pageSize, batchId, lastSkuId };

    return new Promise(async (resolve, reject) => {
        const res = await jdiColorFetch(
            `guide_homepage_getItemFeeds`,
            {
                method: 'POST',
                headers: CONFIG.colorHeader,
                bodyQuery: {
                    type: 'EXT-CHANNEL-SECKILL-PRODUCT',
                    cuPage,
                    pageSize,
                    params: {
                        batchId, lastSkuId
                    }
                }
            }
        )
        if (res.code === "200") {
            resolve(res.data)
        } else {
            reject(res)
        }
    })
}
