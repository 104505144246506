"use client";

import React from "react";
import { AddCart } from "@/apis/service";
import SafeLink from "@/components/SafeLink";
import UKToast from "@/components/UKToast";
import { getUserUUIDFromCookie } from "@/utils/userUtils";
import BigImg from "./compoments/BigImg";
import "./style.scss";
import CARICON from "@/images/icon/car-icon.svg";
import { JDIPrice } from "../../../../lib/jdiClientUtils";
import { formatProductLink } from "@/common-components-src/js/server";

export default function Module({ moduleData }: { moduleData: any }) {
  const { moduleId } = moduleData;
  const initConfig = (moduleData: any): any[] => {
    const { dataStyle, dataBatch } = moduleData;
    try {
      const _productList = (dataBatch?.productList || []).map((item: any) => {
        return item;
      });
      return _productList;
    } catch (e) {
      console.error("uk-pc-category-floor", e);
      return [];
    }
  };

  const productList = initConfig(moduleData);

  /**
   * 加车
   * https://cf.jd.com/pages/viewpage.action?pageId=605887939
   */
  const addCart = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, skuId: string) => {
    e.preventDefault();
    e.stopPropagation();
    const cartuuid = getUserUUIDFromCookie();
    const res = await AddCart({ num: 1, skuId, cartuuid });
    if (res?.success) {
      UKToast.show({
        content: "Added to Cart!",
        icon: "success",
      });
      (window as any).JDIheader.initCartNum();
    } else {
      UKToast.show({
        content: res?.message,
        icon: "error",
      });
    }
  };

  return (
    <div className="uk-pc-category-floor" id={`pcProduct${moduleId}`} style={{ marginBottom: "0px", marginTop: "0px" }}>
      {!!productList.length && (
        <>
          <SafeLink href={formatProductLink(productList[0].productInfo.skuId, productList[0].productInfo.skuName)}>
            <div className="img-box-1">
              <BigImg src={productList[0].image.imageUrl} />
            </div>
          </SafeLink>
          <div className={"uk-pc-category-floor-container6"}>
            {productList.slice(0, 4).map((item: any, index) => {
              return (
                <SafeLink href={formatProductLink(item.productInfo.skuId, item.productInfo.skuName)} key={index} title={item.productInfo.skuName}>
                  <div className="product-box FloatingLayer">
                    <BigImg src={item.productInfo.imageUrl} />
                    <div className="commodity-title">{item.productInfo.skuName}</div>
                    <p className="commodity-price">
                      <JDIPrice symbol="￡" price={item.productInfo.realPrice} uiType={1} />
                      <span
                        className="add-cart HOVERCAR"
                        onClick={(e) => {
                          addCart(e, item.productInfo.skuId);
                        }}
                      >
                        <CARICON></CARICON>
                      </span>
                    </p>
                  </div>
                </SafeLink>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
