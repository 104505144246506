import { useEffect, useRef, useState } from "react"
import style from './style.module.scss'

const Countdown = ({ remainBeginTimes, onCountdownEnd }: any) => {
  const [timer, setTimer] = useState<string | NodeJS.Timeout>('') // 时间组件
  const [hour, setHour] = useState<string | number>("00") //小时
  const [minute, setMinute] = useState<number | string>("00") //分钟
  const [sec, setDec] = useState<number | string>("00") //秒
  const statrTime = useRef(remainBeginTimes)

  // 计算初始剩余时间
  const calculateRemainingTime = () => {
    if (statrTime.current > 0) {
      const totalSeconds = Math.floor(statrTime.current / 1000); // 转换为秒
      setHour(String(Math.floor(totalSeconds / 3600)).padStart(2, "0")) // 计算小时
      setMinute(String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0")) // 计算分钟
      setDec(String(totalSeconds % 60).padStart(2, "0")) // 计算秒
      statrTime.current = statrTime.current - 1000
    } else {
      setHour("00")
      setMinute("00")
      setDec("00") // 如果时间已过，展示为 00
      clearInterval(timer as NodeJS.Timeout)
      setTimer('')
    }
  }

  useEffect(() => {
    const timer = setInterval(calculateRemainingTime, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (statrTime.current < 0) {
      onCountdownEnd();
    }
  }, [statrTime.current]);

  return (
    <div className={style.outTime}>
      <span>{hour}</span> : <span>{minute}</span> : <span>{sec}</span>
    </div>
  )
}

export default Countdown;