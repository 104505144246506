"use client";
import { useEffect, useState } from "react";
import style from "./style.module.scss";
import SafeLink from "@/components/SafeLink";
import JumpLink from "@/components/JumpLink";
import { formatProductLink } from "@/common-components-src/js/server";
import ALLICON from "@/images/icon/all-icon.svg";
import { JDIProductCardV2 } from "@/common-components-src/js/main";
import SaleTips from "@/app/cms/modules/uk-pc-flash-sales/saleTips";
import dayjs from "dayjs";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25223";

interface FlashSalePropTypes {
  moduleData: any;
  currentMills: number;
  currencySymbol: string;
}
export default function Module({ moduleData, currentMills, currencySymbol }: FlashSalePropTypes) {
  //--------------秒杀活动状态--------------
  enum ActivityStatus {
    NOT_START = "not_start",
    ONGOING = "ongoing",
    ENDED = "ended",
  }
  const [countdownStatus, setCountDownStatus] = useState<ActivityStatus>(ActivityStatus.ENDED);

  const pageConfig: any = {};

  const initConfig = (moduleData: any) => {
    const { dataBatch, externalData } = moduleData;
    const externalDataBatchList = externalData?.data?.seckillBatchDtoList[0];
    const startTimestr = new Date(externalDataBatchList?.startTimeStr);
    // const clientNow = Math.floor(new Date().getTime() / 60000); //忽略以秒为单位的偏移量
    // const diff = Math.floor(currentMills / 60000) - clientNow;

    pageConfig.moreLinks = dataBatch?.Config[0].moreBtnLink;
    pageConfig.products = externalDataBatchList?.productList;
    pageConfig.startTimeStamp = externalDataBatchList?.startTime;
    pageConfig.startDate = startTimestr;
    pageConfig.products?.forEach((p: any, index: number) => {
      // --------------商卡信息--------------
      p.type = "1";
      p.currency = currencySymbol;
      p.skuImg = p.imageUrl;
      p.skuName = p.skuName;
      p.url = formatProductLink(p.skuId, p.skuName);

      // --------------商卡样式--------------
      p.width = "220px";
      p.height = "302px";
      p.skuNameStyle = {
        fontSize: "16px",
        color: "#1a1a1a",
      };
      p.containerStyle = {
        flex: "0 0 auto",
      };
      p.skuImgStyle = {
        width: "200px",
        height: "200px",
      };
      p.saleTips = {
        discount: p.extInfo?.labels?.labelData?.["1"]?.value,
        saveX: p.extInfo?.labels?.labelData?.["11"]?.value,
        onlyXLeft: p?.stock < 10 ? p.stock : null,
      };
      p.realPriceJDIPriceParams = {
        integerStyle: {
          fontSize: "24px",
        },
      };
      p.saleTipsDom = SaleTips(p.saleTips);
      p.trackingPointParams = genEventTrackingInfo(p, index);
    });
  };

  // 生成埋点信息
  const genEventTrackingInfo = (product: any, index: number) => {
    const eventTrackingInfo = {} as { [key: string]: any };
    const json_param = { skuid: product.skuId.toString(), index: index };
    const expConfig = {
      useClick: false,
      stay_duration: 0,
      repeated: false,
      area_rate: 0.01,
    };
    eventTrackingInfo["productClickPoint"] = () => sendClickTrack.Home_Pro_FlashSales(json_param);
    eventTrackingInfo["productParam"] = json_param;
    eventTrackingInfo["productExpConfig"] = {
      event_id: EXPOSURE_RECORD.Home_Pro_FlashSales_Expo,
      config: expConfig,
      json_param: json_param,
    };
    return eventTrackingInfo;
  };

  const refreshCountdown = (endTimestamp: number, now = new Date().getTime()) => {
    // const now = new Date().getTime();
    const duration = Math.max(~~((endTimestamp - now) / 1000), 0);
    const h = ~~(duration / 60 / 60) + "";
    const m = ~~((duration - ~~h * 60 * 60) / 60) + "";
    const s = (duration % 60) + "";
    pageConfig.countdown = [h.padStart(2, "0"), m.padStart(2, "0"), s.padStart(2, "0")];
  };

  useEffect(() => {
    const data = moduleData.externalData?.data?.seckillBatchDtoList[0];

    // -----------计算和服务器时间差-----------
    const endTimestamp = data?.endTime;
    const startTimeStamp = data?.startTime;
    const currentTime = data?.currTime;
    const clientNow = new Date().getTime();
    const diff = currentMills - clientNow;

    // -----------更新时间和秒杀状态------------
    const updateCountdown = () => {
      // 如果有活动下发
      if (pageConfig.products && endTimestamp && startTimeStamp && currentTime) {
        // 1. 活动进行中
        // if (new Date().getTime() + diff >= startTimeStamp && new Date().getTime() + diff <= endTimestamp) {
        if (currentTime >= startTimeStamp && currentTime <= endTimestamp) {
          setCountDownStatus(ActivityStatus.ONGOING);
          refreshCountdown(endTimestamp, new Date().getTime() + diff);
          setCountdown(pageConfig.countdown);
          pageConfig.products?.forEach((p: any) => {
            p.shouldShowCart = true;
          });
        }
        // 2. 有24小时内即将开始的活动
        // else if (new Date().getTime() + diff < startTimeStamp && new Date().getTime() + diff - startTimeStamp <= 24 * 60 * 60 * 1000) {
        else if (currentTime && startTimeStamp - currentTime <= 24 * 60 * 60 * 1000) {
          setCountDownStatus(ActivityStatus.NOT_START);
        }
      } else {
        setCountDownStatus(ActivityStatus.ENDED);
      }
    };

    // ----------更新秒杀倒计时----------
    const timer = setInterval(updateCountdown, 1000);
    updateCountdown;
    return () => clearInterval(timer);
  }, [pageConfig.products]);

  initConfig(moduleData);
  refreshCountdown(moduleData.externalData.data?.seckillBatchDtoList[0].endTime);
  const [countdown, setCountdown] = useState(pageConfig.countdown);

  // -------------秒杀楼层组件-------------
  const flashSales = (text: string, hour: string, minute: string, second: string) => {
    return (
      <div className={style["uk-pc-flash-sales"]}>
        <div className={style.bar}>
          <p className={style.title}>Flash Sales</p>
          <JumpLink jumpParams={pageConfig.moreLinks}>
            <div
              className={style.more}
              onClick={() => sendClickTrack.Home_Pro_FlashSales_More()}
              exptag={"exp|" + EXPOSURE_RECORD.Home_Pro_FlashSales_More_Expo}
              data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
            >
              <p>
                <span className={style.timeTitle}>{text}</span>
                <span className={style.timeNum} suppressHydrationWarning>
                  {hour}
                </span>
                <span>:</span>
                <span className={style.timeNum} suppressHydrationWarning>
                  {minute}
                </span>
                <span>:</span>
                <span className={style.timeNum} suppressHydrationWarning>
                  {second}
                </span>
              </p>
              <ALLICON className={style.arrowRight} />
            </div>
          </JumpLink>
        </div>

        <div className={style.products}>
          {pageConfig.products?.map((product: any, index: number) => (
            <JDIProductCardV2 productData={product} key={product.skuId} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {countdownStatus === ActivityStatus.ONGOING && flashSales("Ends in: ", countdown[0], countdown[1], countdown[2])}
      {countdownStatus === ActivityStatus.NOT_START &&
        flashSales(
          "Start: ",
          pageConfig.startDate.getHours().toString().padStart(2, "0"),
          pageConfig.startDate.getMinutes().toString().padStart(2, "0"),
          pageConfig.startDate.getSeconds().toString().padStart(2, "0"),
        )}
    </>
  );
}
