import Link, { LinkProps } from "next/link";

const JumpType = {
  Code: "page-code",
  Url: "custom",
};

interface JumpParams {
  jumpId: null | string;
  jumpType: string;
  jumpDev: null | string;
  jumpValue: any;
}
export default function JumpLink(props: { jumpParams: JumpParams; children?: React.ReactNode; className?: string }) {
  if (!props.jumpParams) return null;
  const { jumpType, jumpValue } = props.jumpParams;
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (jumpType === JumpType.Code) {
      return;
    } else if (jumpType === JumpType.Url) {
      e.preventDefault();
      window.location.href = jumpValue.link;
    }
  };

  let href = "#";
  if (jumpType === JumpType.Code) {
    href = `/cms/${jumpValue.pageCodeInfo?.pageCode}`;
  }

  return (
    <Link href={href} onClick={handleClick} className={props.className}>
      {props.children}
    </Link>
  );
}
