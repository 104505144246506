import React from "react";

import SVGWatermarkNotStarted from "../icons/WatermarkNotStarted.svg";
import SVGWatermarkCollected from "../icons/WatermarkCollected.svg";
import SVGWatermarkEnded from "../icons/WatermarkEnded.svg";
import SVGWatermarkAllClaimed from "../icons/WatermarkAllClaimed.svg";
import { ECouponGetState, ECouponState } from "../type";

const Watermark = ({ couponState, couponGetState }: { couponState: ECouponState; couponGetState: ECouponGetState }) => {
  const className = "Watermark";

  return {
    [ECouponState.NotStarted]: <SVGWatermarkNotStarted className={className} />,
    [ECouponState.InProgress]: couponGetState === 1 ? <SVGWatermarkCollected className={className} /> : null,
    [ECouponState.Expired]: <SVGWatermarkEnded className={className} />,
    [ECouponState.AllClaimed]: <SVGWatermarkAllClaimed className={className} />,
  }[couponState];
};

export default Watermark;
