// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25160
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25160
import { eventTrackingUtils } from "@/utils/eventTrackingUtils";

const sendPv = {
  /** PV */
  Activity: (page_id: string) => {
    eventTrackingUtils.submitPV({ page_id: page_id });
  },
};

interface IExpoTrackParamsRecord {
  /** 商品曝光 */
  ProductExpo: {
    /** 商品id  */
    skuid: string;
    /** 第一价格金额  */
    firprice: number;
    /** 第一价格分类 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L4会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,39-xx人拼购价（使用7）,40-x件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,-100-兜底值,0-小时购主要价格,81-直播间价格,82-神价,90-开普勒价,83-原始价,91-试用价,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,85-店铺会员到手价,86-plus会员价,87-渠道价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价 */
    firpricetype:
      | "1"
      | "2"
      | "3"
      | "4"
      | "5"
      | "6"
      | "7"
      | "8"
      | "9"
      | "10"
      | "11"
      | "12"
      | "13"
      | "14"
      | "15"
      | "16"
      | "17"
      | "18"
      | "19"
      | "20"
      | "21"
      | "22"
      | "23"
      | "24"
      | "26"
      | "27"
      | "28"
      | "29"
      | "30"
      | "31"
      | "32"
      | "33"
      | "34"
      | "35"
      | "36"
      | "37"
      | "38"
      | "39"
      | "40"
      | "41"
      | "42"
      | "43"
      | "44"
      | "45"
      | "46"
      | "47"
      | "48"
      | "49"
      | "50"
      | "51"
      | "52"
      | "53"
      | "54"
      | "73"
      | "74"
      | "75"
      | "76"
      | "77"
      | "78"
      | "79"
      | "-100"
      | "0"
      | "81"
      | "82"
      | "90"
      | "83"
      | "91"
      | "92"
      | "93"
      | "94"
      | "85"
      | "86"
      | "87"
      | "95"
      | "96"
      | "97"
      | "98"
      | "99"
      | "100"
      | "101"
      | "102"
      | "103"
      | "104"
      | "105";
    /** 第二价格金额  */
    secprice: number;
    /** 第二价格类型 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L5会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,40-X件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金（多阶段支付）,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,80-单规格价格,81-直播间价格,82-神价,83-原始价,90-开普勒价,91-试用价,84-第一件XX元/前n件XX元,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价 */
    secpricetype:
      | "1"
      | "2"
      | "3"
      | "4"
      | "5"
      | "6"
      | "7"
      | "8"
      | "9"
      | "10"
      | "11"
      | "12"
      | "13"
      | "14"
      | "15"
      | "16"
      | "17"
      | "18"
      | "19"
      | "20"
      | "21"
      | "22"
      | "23"
      | "24"
      | "26"
      | "27"
      | "28"
      | "29"
      | "30"
      | "31"
      | "32"
      | "33"
      | "34"
      | "35"
      | "36"
      | "37"
      | "38"
      | "40"
      | "41"
      | "42"
      | "43"
      | "44"
      | "45"
      | "46"
      | "47"
      | "48"
      | "49"
      | "50"
      | "51"
      | "52"
      | "53"
      | "54"
      | "73"
      | "74"
      | "75"
      | "76"
      | "77"
      | "78"
      | "79"
      | "80"
      | "81"
      | "82"
      | "83"
      | "90"
      | "91"
      | "84"
      | "92"
      | "93"
      | "94"
      | "95"
      | "96"
      | "97"
      | "98"
      | "99"
      | "100"
      | "101"
      | "102"
      | "103"
      | "104"
      | "105";
    /** 位置  */
    index: number;
    /** 置信文案  */
    freqTag: string;
    /** 优惠券展示文案  */
    couponInfo: string;
    /** 单品促销  */
    single_promotion: string;
    /** 总价促销  */
    amount_promotion: string;
    /** 时效标签  */
    time_label: string;
  };
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord, string>> = Object.freeze({
  /** 商品曝光 */
  ProductExpo: "_ProductExpo",
});

interface IClickTrackParamsRecord {
  /** 1. 商品点击 */
  Activity_Product: {
    /** 商品id  */
    skuid: string;
    /** 第一价格金额  */
    firprice: number;
    /** 第一价格分类 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L4会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,39-xx人拼购价（使用7）,40-x件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,-100-兜底值,0-小时购主要价格,81-直播间价格,82-神价,90-开普勒价,83-原始价,91-试用价,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,85-店铺会员到手价,86-plus会员价,87-渠道价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价 */
    firpricetype:
      | "1"
      | "2"
      | "3"
      | "4"
      | "5"
      | "6"
      | "7"
      | "8"
      | "9"
      | "10"
      | "11"
      | "12"
      | "13"
      | "14"
      | "15"
      | "16"
      | "17"
      | "18"
      | "19"
      | "20"
      | "21"
      | "22"
      | "23"
      | "24"
      | "26"
      | "27"
      | "28"
      | "29"
      | "30"
      | "31"
      | "32"
      | "33"
      | "34"
      | "35"
      | "36"
      | "37"
      | "38"
      | "39"
      | "40"
      | "41"
      | "42"
      | "43"
      | "44"
      | "45"
      | "46"
      | "47"
      | "48"
      | "49"
      | "50"
      | "51"
      | "52"
      | "53"
      | "54"
      | "73"
      | "74"
      | "75"
      | "76"
      | "77"
      | "78"
      | "79"
      | "-100"
      | "0"
      | "81"
      | "82"
      | "90"
      | "83"
      | "91"
      | "92"
      | "93"
      | "94"
      | "85"
      | "86"
      | "87"
      | "95"
      | "96"
      | "97"
      | "98"
      | "99"
      | "100"
      | "101"
      | "102"
      | "103"
      | "104"
      | "105";
    /** 第二价格金额  */
    secprice: number;
    /** 第二价格类型 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L5会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,40-X件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金（多阶段支付）,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,80-单规格价格,81-直播间价格,82-神价,83-原始价,90-开普勒价,91-试用价,84-第一件XX元/前n件XX元,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价 */
    secpricetype:
      | "1"
      | "2"
      | "3"
      | "4"
      | "5"
      | "6"
      | "7"
      | "8"
      | "9"
      | "10"
      | "11"
      | "12"
      | "13"
      | "14"
      | "15"
      | "16"
      | "17"
      | "18"
      | "19"
      | "20"
      | "21"
      | "22"
      | "23"
      | "24"
      | "26"
      | "27"
      | "28"
      | "29"
      | "30"
      | "31"
      | "32"
      | "33"
      | "34"
      | "35"
      | "36"
      | "37"
      | "38"
      | "40"
      | "41"
      | "42"
      | "43"
      | "44"
      | "45"
      | "46"
      | "47"
      | "48"
      | "49"
      | "50"
      | "51"
      | "52"
      | "53"
      | "54"
      | "73"
      | "74"
      | "75"
      | "76"
      | "77"
      | "78"
      | "79"
      | "80"
      | "81"
      | "82"
      | "83"
      | "90"
      | "91"
      | "84"
      | "92"
      | "93"
      | "94"
      | "95"
      | "96"
      | "97"
      | "98"
      | "99"
      | "100"
      | "101"
      | "102"
      | "103"
      | "104"
      | "105";
    /** 位置  */
    index: number;
    /** 置信文案  */
    freqTag: string;
    /** 优惠券展示文案  */
    couponInfo: string;
    /** 单品促销  */
    single_promotion: string;
    /** 总价促销  */
    amount_promotion: string;
    /** 时效标签  */
    time_label: string;
  };

  /** 2. 商品加购 */
  Activity_AddToCart: {
    /** 商品id  */
    skuid: string;
    /** 第一价格金额  */
    firprice: number;
    /** 第一价格分类 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L4会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,39-xx人拼购价（使用7）,40-x件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,-100-兜底值,0-小时购主要价格,81-直播间价格,82-神价,90-开普勒价,83-原始价,91-试用价,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,85-店铺会员到手价,86-plus会员价,87-渠道价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价 */
    firpricetype:
      | "1"
      | "2"
      | "3"
      | "4"
      | "5"
      | "6"
      | "7"
      | "8"
      | "9"
      | "10"
      | "11"
      | "12"
      | "13"
      | "14"
      | "15"
      | "16"
      | "17"
      | "18"
      | "19"
      | "20"
      | "21"
      | "22"
      | "23"
      | "24"
      | "26"
      | "27"
      | "28"
      | "29"
      | "30"
      | "31"
      | "32"
      | "33"
      | "34"
      | "35"
      | "36"
      | "37"
      | "38"
      | "39"
      | "40"
      | "41"
      | "42"
      | "43"
      | "44"
      | "45"
      | "46"
      | "47"
      | "48"
      | "49"
      | "50"
      | "51"
      | "52"
      | "53"
      | "54"
      | "73"
      | "74"
      | "75"
      | "76"
      | "77"
      | "78"
      | "79"
      | "-100"
      | "0"
      | "81"
      | "82"
      | "90"
      | "83"
      | "91"
      | "92"
      | "93"
      | "94"
      | "85"
      | "86"
      | "87"
      | "95"
      | "96"
      | "97"
      | "98"
      | "99"
      | "100"
      | "101"
      | "102"
      | "103"
      | "104"
      | "105";
    /** 第二价格金额  */
    secprice: number;
    /** 第二价格类型 1-新人价,2-重逢价,3-粉丝价,4-闪购价,5-学生价,6-企业价,7-拼购价,8-家庭价,9-plus价,10-合约价,11-到手价,12-店铺会员价-L1会员,13-店铺会员价-L2会员,14-店铺会员价-L3会员,15-店铺会员价-L4会员,16-店铺会员价-L5会员,17-1号店会员价,18-电子书价,19-sam价,20-门店价,21-进口新客价,22-企业新人价,23-专属价,24-首单新人价,26-秒杀价,27-秒杀专享价,28-城市专享价,29-临期特卖,30-商场同款,31-商品定制价,32-亲子价,33-超市新人价,34-预售价,35-EDLP,36-砍后价,37-拼团价,38-陪伴价,40-X件单价约xx,41-xx件单价,42-天天低价,43-低于30天均价,44-京东价,45-企业会员价,46-补贴价,47-套装价,48-区间价,49-百补补贴价,50-百补日常价,51-租赁价,52-划线价,53-拍卖当前价,54-起拍价,73-定期购预估总价,74-订金（多阶段支付）,75-区域专享价,76-星巴克会员价,77-医药商品单盒价,78-内购价,79-小时达新人到手价,80-单规格价格,81-直播间价格,82-神价,83-原始价,90-开普勒价,91-试用价,84-第一件XX元/前n件XX元,92-百补多人团价,93-秒送兑换券价（0元）,94-预售券后价,95-单促价,96-儿童价,97-婴儿价,98-火车票价格,99-直播专享价,100-直播专享价到手价,101-直播专享券到手价,102-预估新人价,103-未登录到手价,104-国补到手价,105-国补拉新价 */
    secpricetype:
      | "1"
      | "2"
      | "3"
      | "4"
      | "5"
      | "6"
      | "7"
      | "8"
      | "9"
      | "10"
      | "11"
      | "12"
      | "13"
      | "14"
      | "15"
      | "16"
      | "17"
      | "18"
      | "19"
      | "20"
      | "21"
      | "22"
      | "23"
      | "24"
      | "26"
      | "27"
      | "28"
      | "29"
      | "30"
      | "31"
      | "32"
      | "33"
      | "34"
      | "35"
      | "36"
      | "37"
      | "38"
      | "40"
      | "41"
      | "42"
      | "43"
      | "44"
      | "45"
      | "46"
      | "47"
      | "48"
      | "49"
      | "50"
      | "51"
      | "52"
      | "53"
      | "54"
      | "73"
      | "74"
      | "75"
      | "76"
      | "77"
      | "78"
      | "79"
      | "80"
      | "81"
      | "82"
      | "83"
      | "90"
      | "91"
      | "84"
      | "92"
      | "93"
      | "94"
      | "95"
      | "96"
      | "97"
      | "98"
      | "99"
      | "100"
      | "101"
      | "102"
      | "103"
      | "104"
      | "105";
    /** 位置  */
    index: number;
    /** 置信文案  */
    freqTag: string;
    /** 优惠券展示文案  */
    couponInfo: string;
    /** 单品促销  */
    single_promotion: string;
    /** 总价促销  */
    amount_promotion: string;
    /** 时效标签  */
    time_label: string;
  };
}

const sendClickTrack = {
  /** 点击事件上报-商品点击 */
  Product: (page_id: string, json_param: IClickTrackParamsRecord["Activity_Product"]) => {
    eventTrackingUtils.submitCL({ event_id: `${page_id}_Product`, json_param });
  },
  /** 点击事件上报-商品加购 */
  AddToCart: (page_id: string, json_param: IClickTrackParamsRecord["Activity_AddToCart"]) => {
    eventTrackingUtils.submitCL({ event_id: `${page_id}_AddToCart`, json_param });
  },
};

export { EXPOSURE_RECORD, sendClickTrack, sendPv };
export type { IExpoTrackParamsRecord };
