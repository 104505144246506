"use client";

import React, { useState } from "react";
import { ECouponGetState, ECouponState, ECouponType, ICouponInfo } from "../type";
import Button from "@mui/material/Button";
import { getCoupon, queryCouponId } from "@/apis/land/land.api";
import UKToast from "@/components/UKToast";
import { useRouter } from "next/navigation";

const MButtonText = {
  Collect: "Collect",
  GoShopping: "Go shopping",
};

const CouponContent = ({ couponInfo, setCouponInfo }: { couponInfo: ICouponInfo; setCouponInfo: (couponInfo: ICouponInfo) => void }) => {
  const handleCouponStateUpdate = (code: string, msg: string) => {
    switch (code) {
      case "400018":
        UKToast.show({
          content: msg ?? "All the coupons have been claimed.",
          icon: "error",
        });
        setCouponInfo({ ...couponInfo, couponState: ECouponState.AllClaimed });
        break;
      case "400024":
        UKToast.show({
          content: msg ?? "The offer has expired.",
          icon: "error",
        });
        setCouponInfo({ ...couponInfo, couponState: ECouponState.Expired });
        break;
      default:
        if (code !== "401") {
          UKToast.show({
            content: msg ?? "Error",
            icon: "error",
          });
        }
    }
  };
  const router = useRouter();

  const [isCollecting, setIsCollecting] = useState(false);
  async function collectCoupon(couponInfo: ICouponInfo) {
    const { activityId, roleId, encryptedKey } = couponInfo;
    if (isCollecting) return;
    try {
      setIsCollecting(true);
      const res = await getCoupon({ activityId, roleId, encryptedKey });
      if (res.code === 200 || res.code === "200") {
        if (res.data.result && res.data.takeStatus === 1) {
          UKToast.show({
            content: res?.msg || "Success",
            icon: "success",
          });
          setCouponInfo({ ...couponInfo, couponGetState: ECouponGetState.PendingUse });
        }
      } else {
        handleCouponStateUpdate(res.code, res.msg);
      }
    } catch {
      UKToast.show({
        content: "Error",
        icon: "error",
      });
    } finally {
      setIsCollecting(false);
    }
  }

  const [isQueryingCouponId, setIsQueryingCouponId] = useState(false);
  async function goShopping({ batchId }: ICouponInfo) {
    if (isQueryingCouponId) return;
    try {
      const res = await queryCouponId({ batchId });
      if (res.code === 200 || res.code === "200") {
        const { couponId } = res.data;
        router.push(`/s-for-addon?&type=1&couponId=${couponId}&batchId=${batchId}`);
      } else {
        UKToast.show({
          content: res.msg ?? "Error",
          icon: "error",
        });
      }
    } catch (e) {
      UKToast.show({
        content: "Error",
        icon: "error",
      });
    } finally {
      setIsQueryingCouponId(false);
    }
  }

  return (
    <div className="CouponContent">
      <div className="left">
        <div className="off">
          <span className="offDiscount">
            <span className="discount">£{couponInfo.discount}</span>
            {couponInfo.couponType === ECouponType.Off && <span className="subscript">Off</span>}
            {couponInfo.couponType === ECouponType.ShippingOff && <span className="subscript">Shipping Off</span>}
            <span className="quotaLimit">{couponInfo.quota ? `Over £${couponInfo.quota}` : "No purchase limit"}</span>
          </span>
        </div>
        {couponInfo.couponUseLimit && couponInfo.couponType !== ECouponType.ShippingOff && <div className="couponUseLimit">{couponInfo.couponUseLimit}</div>}
      </div>
      <div className="right">
        {/* 未开始 */}
        {couponInfo.couponState === ECouponState.NotStarted && (
          <Button sx={{ "&.Mui-disabled": { background: "#FF9FA7", color: "#F5F6FA" } }} disabled variant="contained">
            {MButtonText.Collect}
          </Button>
        )}
        {/* 进行中  未领取*/}
        {couponInfo.couponState === ECouponState.InProgress && couponInfo.couponGetState === ECouponGetState.NotReceived && (
          <Button variant="contained" sx={{ background: "#CC0C1C" }} onClick={() => collectCoupon(couponInfo)}>
            {MButtonText.Collect}
          </Button>
        )}
        {/* 进行中  待使用 且不为运费券 */}
        {couponInfo.couponState === ECouponState.InProgress &&
          couponInfo.couponGetState === ECouponGetState.PendingUse &&
          couponInfo.couponType !== ECouponType.ShippingOff && (
            <Button sx={{ background: "#fff", border: "#CC0C1C .5px solid", color: "#CC0C1C" }} onClick={() => goShopping(couponInfo)}>
              {MButtonText.GoShopping}
            </Button>
          )}
        {/* 进行中  已使用&已过期*/}
        {couponInfo.couponState === ECouponState.InProgress && couponInfo.couponGetState === ECouponGetState.UsedOrExpired && (
          <Button variant="contained" sx={{ background: "#CC0C1C" }}>
            {MButtonText.Collect}
          </Button>
        )}
        {/* 已过期 已抢光 */}
        {[ECouponState.Expired, ECouponState.AllClaimed].includes(couponInfo.couponState) && (
          <Button sx={{ "&.Mui-disabled": { background: "#C2C4CC", color: "#F5F6FA" } }} disabled variant="contained">
            {MButtonText.Collect}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CouponContent;
