"use client";
import { useEffect, useRef, useState } from "react";
import dayjs from 'dayjs';
import style from "../../style.module.scss";
import SVGScrollController from '@/assets/icons/SVGScrollController.svg'
import { EXPOSURE_RECORD, sendClickTrack } from '@/tracks/25208'

export default function TabBar<node>(props: {
  batchList: any,
  clickTabBar: number,
  currentMills: number,
  onSelect: (batchId: number, item: any) => void,
  onCountdownEnd: () => void,
}) {
  const { batchList, currentMills, clickTabBar, onSelect, onCountdownEnd } = props // 父组件参数
  const statrTime = useRef(currentMills || dayjs().valueOf())
  const [timer, setTimer] = useState<string | NodeJS.Timeout>('') // 时间组件
  const [hour, setHour] = useState<string | number>("00") //小时
  const [minute, setMinute] = useState<number | string>("00") //分钟
  const [sec, setDec] = useState<number | string>("00") //秒
  const [remainingTime, setRemainingTime] = useState<number>(0)

  const tabBarWinRef = useRef<HTMLDivElement>(null)
  const [startIndex, setStartIndex] = useState<number>(0); // 起点下标
  const categoryItemWidth = 180;
  const maxScrollCategories = 6; // 一次性最多滚动的类目数量
  const maxVisibleCategories = 6; // 最多展示类目数量

  useEffect(() => {
    const timer = setInterval(calculateRemainingTime, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (remainingTime < 0) {
      onCountdownEnd();
    }
  }, [remainingTime]);

  // 计算初始剩余时间
  const calculateRemainingTime = () => {
    if (batchList && batchList.length > 0) {
      // 获取当前的伦敦时间
      const remainingTime = batchList[0].endTime - statrTime.current; // 计算剩余时间
      statrTime.current = statrTime.current + 1000
      setRemainingTime(remainingTime)
      if (remainingTime > 0) {
        const totalSeconds = Math.floor(remainingTime / 1000); // 转换为秒
        setHour(String(Math.floor(totalSeconds / 3600)).padStart(2, "0")) // 计算小时
        setMinute(String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0")) // 计算分钟
        setDec(String(totalSeconds % 60).padStart(2, "0")) // 计算秒
      } else {
        setHour("00")
        setMinute("00")
        setDec("00") // 如果时间已过，展示为 00
        clearInterval(timer as NodeJS.Timeout)
        setTimer('')
      }
    }
  }

  // 点击tabBar 事件
  function handleSelect(batchId: number, item: any, index) {
    const prams = {
      tabName: item.tabName,
      index
    }
    sendClickTrack.MActivity_FlashSales_SessionTab(prams)
    onSelect(batchId, item)
  }

  // 点击类目向左滚动
  const handlePrevClick = () => {
    const categoryIndex = Math.max(startIndex - maxScrollCategories, 0);
    setStartIndex(categoryIndex);
    if (tabBarWinRef.current) {
      tabBarWinRef.current.scrollTo({
        left: categoryIndex * (categoryItemWidth + 8),
        behavior: "smooth",
      });
    }
  };
    // 点击类目向右滚动
    const handleNextClick = () => {
      // 最多滚动8个不能超出类目数量
      const categoryIndex = Math.min(startIndex + maxScrollCategories, batchList.length - maxVisibleCategories);
      setStartIndex(categoryIndex);
      if (tabBarWinRef.current) {
        tabBarWinRef.current.scrollTo({
          left: categoryIndex * (categoryItemWidth + 8),
          behavior: "smooth",
        });
      }
    };

  return (
    <div className={style.tabBar_wrap}>
      {
        batchList.length > 6 ?
          <button onClick={handlePrevClick} disabled={startIndex === 0} className={style.leftArrow}>
            <SVGScrollController />
          </button> : null
      }
      <div className={style.tabBar_window} ref={tabBarWinRef}>
        <div className={style.tabBar_box}>
          {
            batchList && batchList.length > 0 ? (
              batchList.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    exptag={"exp|" + EXPOSURE_RECORD.MActivity_FlashSales_SessionTabExpo}
                    data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                    data-exptag-json-param={JSON.stringify({ index, tabName: item.tabName })}
                    className={`${style.tabBar_item} ${clickTabBar === item.batchId ? style.isActive : ''}`}
                    onClick={() => handleSelect(item.batchId, item, index)}>
                    {
                      item.currentBatch
                        ?
                        <div className={style.EndsIn} >Ends in</div>
                        :
                        <div className={style.title}>{item.feTime}</div>
                    }
                    {
                      item.currentBatch
                        ?
                        <div className={style.outTime}>
                          <span>{hour}</span> : <span>{minute}</span> : <span>{sec}</span>
                        </div>
                        :
                        <div className={style.sep_wrap}>
                          {item.feDateTime}
                        </div>
                    }
                  </div>
                )
              })

            ) : ''
          }

        </div>
      </div>
      {
        batchList.length > 6 && (
          <button onClick={handleNextClick} disabled={startIndex >= batchList.length - maxVisibleCategories} className={style.rightArrow}>
            <SVGScrollController />
          </button>
        )}
    </div>
  )
}
