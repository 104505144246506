export function getUrlPathName(urlPathName: string, limit = 20) {
  return urlPathName
    ?.replace(/[^a-zA-Z0-9\s]/g, "")
    ?.split(" ")
    ?.slice(0, limit)
    ?.join("-");
}

export function capitalizeEachWord(str: string) {
  if (typeof str !== "string" || str.length === 0) {
    return ""; // Handle empty or non-string inputs
  }
  return str
    .toLowerCase() // Convert the entire string to lowercase first
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words back into a single string
}
