"use client";
import SafeLink from "@/components/SafeLink";
import JumpLink from "@/components/JumpLink";
import style from "./style.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper as SwiperCore } from "swiper/types";
import { useRef, useState } from "react";
import Prev from "@/assets/icons/prev.svg";
import Next from "@/assets/icons/next.svg";
import Image from "next/image";
import { formatProductLink } from "@/common-components-src/js/server";
import { sendClickTrack, EXPOSURE_RECORD, type IExpoTrackParamsRecord } from "@/tracks/25223";

export default function Module({ moduleData }: { moduleData: any }) {
  const PageConfig: any = {};
  const [isDataReady, setIsDataReady] = useState(false);
  const swiperRef = useRef<SwiperCore>();

  const initConfig = (moduleData: any) => {
    PageConfig.data = moduleData.dataBatch?.carouselList;
  };

  initConfig(moduleData);

  const handleClick = (index: number) => {
    sendClickTrack.Home_Banner({ index: index });
  };

  return (
    <div className={style["uk-pc-carousel-small"]}>
      <Swiper
        slidesPerView={1}
        loop={true}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className={style.mySwiper}
        style={{
          visibility: isDataReady ? "visible" : "hidden",
        }}
        onSwiper={() => {
          setIsDataReady(true);
        }}
      >
        {PageConfig.data.map((carousel: any, index: any) => (
          <SwiperSlide key={index} onClick={() => handleClick(index)}>
            {carousel.jump ? (
              <JumpLink jumpParams={carousel?.jump}>
                <div
                  className={style.slideItem}
                  exptag={"exp|" + EXPOSURE_RECORD.Home_Banner_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  data-exptag-json-param={JSON.stringify({ index: index })}
                >
                  <img loading="lazy" src={carousel?.image?.imageUrl} width={carousel?.image?.imageWidth} height={carousel?.image?.imageHeight} alt="" />
                </div>
              </JumpLink>
            ) : (
              <div
                className={style.slideItem}
                exptag={"exp|" + EXPOSURE_RECORD.Home_Banner_Expo}
                data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                data-exptag-json-param={JSON.stringify({ index: index })}
              >
                <img loading="lazy" src={carousel?.image?.imageUrl} width={carousel?.image?.imageWidth} height={carousel?.image?.imageHeight} alt="" />
              </div>
            )}
          </SwiperSlide>
        ))}
        <div className={style.buttonGroup}>
          <button className={style.prev} onClick={() => swiperRef.current?.slidePrev()}>
            <Prev />
          </button>
          <button className={style.next} onClick={() => swiperRef.current?.slideNext()}>
            <Next />
          </button>
        </div>
      </Swiper>
    </div>
  );
}
