"use client";
import style from "../../style.module.scss";
import SafeLink from "@/components/SafeLink";
import UKToast from "@/components/UKToast";
import { JDIPrice } from "../../../../../../lib/jdiClientUtils";

import { formatProductLink } from "@/common-components-src/js/server"
import { getUserUUIDFromCookie } from "@/utils/userUtils";
import { AddCart } from "@/apis/service";
import DownloadDialog from "@/components/downloadDialog";
import { useState } from "react";
import { EXPOSURE_RECORD, sendClickTrack } from '@/tracks/25208'

// assets\icons
export default function ProductList(props: {
  isSeckillFlag: boolean
  productList: any
  curTabInfo?: any
}) {

  const { isSeckillFlag, productList, curTabInfo } = props
  console.log('sxt-productList', productList);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
  const currency = "￡";
  const comparePrice = (originalPrice: string, realPrice: string): boolean => {
    return Number(realPrice) < Number(originalPrice);
  };
  const addCart = async (product: any, index:number) => {
    sendClickTrack.MActivity_FlashSales_AddToCart(trackParams(index, product));
    const cartuuid = getUserUUIDFromCookie();
    const res = await AddCart({ num: 1, skuId:product.skuId, cartuuid })
    if (res?.success) {
      UKToast.show({
        content: "Added to Cart!", // 弹窗内容
        icon: "success", // 可选：success, fail, loading, warn, info，影响图标
      });
      (window as any).JDIheader.initCartNum()
    } else {
      UKToast.show({
        content: "Added to cart Failed please try again", // 弹窗内容
        icon: "error", // 可选：success, fail, loading, warn, info，影响图标
      });
    }
  };

  //处理营销标签
  const getExtInfoOffer = (extInfo: any) => {
    //判断是否包含营销标签
    if (extInfo && extInfo.labels && extInfo.labels.labelKeys && extInfo.labels.labelKeys.includes('1')) {
      return extInfo.labels.labelData['1'].value
    } else if (extInfo && extInfo.labels && extInfo.labels.labelKeys && extInfo.labels.labelKeys.includes('11')) {
      return extInfo.labels.labelData['11'].value
    }
    return
  }

  const getExtInfoOnly = (extInfo: any) => {
    //判断是否包含营销标签
    if (extInfo && extInfo.labels && extInfo.labels.labelKeys && extInfo.labels.labelKeys.includes('21')) {
      return extInfo.labels.labelData['21'].value
    } else if (extInfo && extInfo.labels && extInfo.labels.labelKeys && extInfo.labels.labelKeys.includes('20')) {
      return extInfo.labels.labelData['20'].value
    }
    return
  }

  //打开下载弹窗组件
  const openDownloadModel = () => {
    setOpenDownloadDialog(true)
  }
  const trackParams = (index: number, item: any) => {
    return {
      skuid: item?.skuId,
      firprice: item?.originalPrice,
      firpricetype: "52",
      secprice: item?.realPrice,
      secpricetype: "26",
      index,
      saleRatio: item.extInfo.seckillRate,
      ...curTabInfo,
    };
  };

  return (
    <div className={style.content_wrap}>
      {
        productList && productList.length > 0 ?
          (
            productList.map((item: any, index: number) => {
              //判断是否是无货状态
              let isOutOfStock = item.extInfo && item.extInfo.labels && item.extInfo.labels.labelKeys && item.extInfo.labels.labelKeys.includes('21') ? true : false
              return (
                <div
                  key={item.skuId}
                  className={style.content_item}
                  exptag={"exp|" + EXPOSURE_RECORD.MActivity_FlashSales_ProductExpo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                  data-exptag-json-param={JSON.stringify(trackParams(index, item))}
                >
                  <SafeLink 
                    href={formatProductLink(item.skuId, item.skuName)} 
                    key={item.skuId}
                    onClick={() => sendClickTrack.MActivity_FlashSales_Product(trackParams(index, item))}
                  >
                    <div className={style.image_box} >
                      <img className={`${isOutOfStock ? style.op50 : ''}`} src={item.imageUrl} alt={item.skuName} />
                      {/* 商品图片背景及hover遮罩 */}
                      <div className={style.UK_mask}></div>
                      {
                        isOutOfStock ?
                          <div className={style.UK_outOfStock}>
                            <div className={style.UK_outOfStock_text}>{item.currentBatch ? 'Sold Out' : 'Out of stock'}</div>
                          </div> : null
                      }
                    </div>

                  </SafeLink>
                  <div className={style.content_item_main}>
                    <div className={style.item_main_title}>
                      <div className={style.discount_rate_text}>
                        <SafeLink 
                          href={formatProductLink(item.skuId, item.skuName)}
                          key={item.skuId}
                          onClick={() => sendClickTrack.MActivity_FlashSales_Product(trackParams(index, item))}
                        >
                          {item.skuName}
                        </SafeLink>
                      </div>
                    </div>
                    {
                      item.currentBatch ?
                        (
                          <div className={style.progressContainer}>
                            <div className={`${style.progressBar} ${isOutOfStock ? style.isOutOfStockBar : ''}`}>
                              {
                                !isOutOfStock &&
                                <div className={style.progressAnimate} style={{ width: `${item.extInfo.seckillRate < 20 ? 20 : item.extInfo.seckillRate}%` }}>
                                </div>
                              }
                            </div>
                            <div className={style.sold}>
                              {
                                getExtInfoOnly(item.extInfo) ? getExtInfoOnly(item.extInfo) : ''
                              }
                            </div>
                          </div>
                        )
                        :
                        ''
                    }
                    <div className={style.item_buy_seckill_btn} >
                      <div className={style.item_main_amount_money_box}>
                        <JDIPrice
                          symbol={currency}
                          price={item.realPrice}
                          uiType={1}
                          containerStyle={{ whiteSpace: 'nowrap', lineHeight: '28px' }}
                          integerStyle={{ fontSize: '22px' }}
                          decimalPlaceStyle={{ fontSize: '18px' }}
                          symbolStyle={{ fontSize: '18px' }} />
                        <span className={style.off}>{getExtInfoOffer(item.extInfo)}</span>
                      </div>

                      <div className={style.item_join_shop_btn} >
                        {
                          isSeckillFlag ?
                            <div className={style.isSeckill}>
                              {
                                isOutOfStock ?
                                  <div className={style.isOutOfStock}>{item.currentBatch ? 'Sold Out' : 'Out of stock'}</div> :
                                  <div className={style.addCartBtn} onClick={() => addCart(item,index)}>
                                    <img className={style.seckill_img} src={"https://st.joy-sourcing.com/website/home/seckill.png"}></img>
                                  </div>
                              }
                            </div> :
                            <div className={style.unSeckill} onClick={() => openDownloadModel()}>
                              {
                                item.reserveStatus === 0 ?
                                  <span>Reserve</span> :
                                  <span className={style.isReserved}>Reserved</span>
                              }
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          )
          :
          ''
      }
      <DownloadDialog open={openDownloadDialog} closeFn={() => setOpenDownloadDialog(false)} />
    </div>
  )
}
