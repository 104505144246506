import { jdiColorFetch } from '../../../../../../apis/colorFetchV2';
import { cmsConfig as CONFIG } from "../../../../common/cmsConfig";
/**
* 获取热销数据
* @param params 
* @returns 
*/

export const queryNewcomerApi = (params: { cuPage: number, pageSize: number, activityId?: number, scrollId?: string | null, }): Promise<any> => {

    const { cuPage, pageSize, activityId, scrollId } = params

    return new Promise(async (resolve, reject) => {
        const res = await jdiColorFetch(
            `guide_homepage_getItemFeeds`,
            {
                method: 'POST',
                headers: CONFIG.colorHeader,
                bodyQuery: {
                    type: 'EXT-CHANNEL-NEWUSER-PRODUCT',
                    cuPage,
                    pageSize,
                    params: {
                        activityId,
                        scrollId
                    }
                }
            }
        )
        if (res.code === "200") {
            resolve(res.data)
        } else {
            reject(res)
        }
    })
}

export const queryProductFeeds = (params: { cuPage: number, pageSize: number, batchId?: number, lastSkuId?: string, }): Promise<any> => {

    const { cuPage, pageSize, batchId, lastSkuId } = params

    return new Promise(async (resolve, reject) => {
        const res = await jdiColorFetch(
            `guide_homepage_getItemFeeds`,
            {
                method: 'POST',
                headers: CONFIG.colorHeader,
                bodyQuery: {
                    type: 'EXT-CHANNEL-SECKILL-PRODUCT',
                    cuPage,
                    pageSize,
                    params: {
                        batchId, lastSkuId
                    }
                }
            }
        )
        if (res.code === "200") {
            resolve(res.data)
        } else {
            reject(res)
        }
    })
}

// 判断是否是新人

export const isNewcomerApi = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        const res = await jdiColorFetch(
            `guide_homepage_getUserIdentity`,
            {
                method: 'POST',
                headers: CONFIG.colorHeader,
                bodyQuery: {
                    identityTypes: 'IDENTITY_NEWCOMER',
                }
            }
        )
        if (res.code === "200") {
            resolve(res.data)
        } else {
            reject(res)
        }
    })
}