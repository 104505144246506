import { cmsConfig as CONFIG } from "@/app/cms/common/cmsConfig";
import { TERMINAL } from "@/constants/cms";
import { jdiColorFetch } from "@/apis/colorFetchV2";
import type { PageVo, ExtInfo, ProductDetailVo } from "./land.interface";

/**
 * 获取热销数据
 * @param params
 * @returns
 */
export const queryFeeds = (params: { type: string; cuPage: number; pageSize: number; mapExInfo?: any }): Promise<PageVo<ProductDetailVo>> => {
  return new Promise(async (resolve, reject) => {
    const res = await jdiColorFetch("guide_homepage_getItemFeeds", {
      method: "POST",
      headers: CONFIG.colorHeader,
      bodyQuery: {
        systemType: 1,
        businessType: 2,
        terminal: TERMINAL.PC,
        type: params.type,
        cuPage: params.cuPage,
        pageSize: params.pageSize,
        params: {
          ...params.mapExInfo,
        },
      },
    });
    console.log(res);

    if (res.code === "200") {
      resolve(res.data);
    } else {
      reject(res);
    }
  });
};

/**
 * 领取优惠券
 * @param params
 * @returns
 */
export const getCoupon = async (params) => {
  const res = await jdiColorFetch("guide_homepage_getCoupon", {
    method: "POST",
    headers: CONFIG.colorHeader,
    bodyQuery: {
      ...params,
    },
  });
  return res;
};

/**
 * 根据 batchId 查询对应单张用户可用券
 */
export const queryCouponId = async (params: { batchId: number }) => {
  const res = await jdiColorFetch("guide_homepage_getUserCoupon", {
    method: "POST",
    headers: CONFIG.colorHeader,
    bodyQuery: {
      ...params,
    },
  });
  return res;
};
