// copy from 原来的cms代码
// git@coding.jd.com:international-frontend/intl-jdi-cms-ssr.git

export const TERMINAL = {
  // (1, "微信小程序"),
  APPLET: 1,
  // (2, "电脑端"),
  PC: 2,
  // (3, "IOS端"),
  IOS: 3,
  // (4, "安卓端"),
  ANDROID: 4,
  //(5, "H5");
  H5: 5,
};
