"use client";

import { useState, useEffect, useMemo } from "react";
import { ECouponGetState, ECouponState, ICouponInfo } from "../type";

const TimestampForOneDay = 24 * 60 * 60 * 1000;

function CountdownBar({
  couponInfo,
  currentMills,
  setCouponInfo,
}: {
  couponInfo: ICouponInfo;
  currentMills: number;
  setCouponInfo: (updatedCoupon: ICouponInfo) => void;
}) {
  const [timeLeft, setTimeLeft] = useState(0);
  const [currentMillsDeprecated, setCurrentMillsDeprecated] = useState(false);
  const className = useMemo(() => {
    const { couponState } = couponInfo;
    if (couponState === ECouponState.NotStarted) {
      return "NotStarted";
    } else if (couponState === ECouponState.InProgress) {
      return "InProgress";
    }
  }, [couponInfo]);

  const timeDescText = useMemo(() => {
    const { couponState } = couponInfo;
    if (couponState === ECouponState.NotStarted) {
      return "Collect start in";
    } else if (couponState === ECouponState.InProgress) {
      return "Collect end in";
    }
  }, [couponInfo]);

  useEffect(() => {
    const { activityBeginTime, activityEndTime, couponState, couponGetState } = couponInfo;

    const claimTime = activityBeginTime ? activityBeginTime - currentMills : 0;
    const endTime = activityEndTime ? activityEndTime - (currentMillsDeprecated ? activityBeginTime : currentMills) : 0;
    let interval: NodeJS.Timeout | null = null;

    function clearCountdown() {
      if (interval) clearInterval(interval);
      setTimeLeft(0);
    }

    const countdown = (duration: number) => {
      let remainingTime = duration;
      setTimeLeft(remainingTime);
      interval = setInterval(() => {
        if (remainingTime < 2000) {
          if (couponState === ECouponState.NotStarted) {
            setCouponInfo({ ...couponInfo, couponState: ECouponState.InProgress });
            setCurrentMillsDeprecated(true);
          } else if (couponState === ECouponState.InProgress) {
            setCouponInfo({ ...couponInfo, couponState: ECouponState.Expired });
          }
          clearCountdown();
        } else {
          remainingTime -= 1000;
          setTimeLeft(remainingTime);
        }
      }, 1000);
    };

    if (couponState === ECouponState.NotStarted && claimTime < TimestampForOneDay && claimTime > 0) {
      countdown(claimTime);
    } else if (couponState === ECouponState.InProgress && couponGetState === ECouponGetState.NotReceived && endTime < TimestampForOneDay && endTime > 0) {
      countdown(endTime);
    } else {
      clearCountdown();
    }

    return () => {
      clearCountdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponInfo, currentMills, currentMillsDeprecated]); // 不需要依赖 setCouponInfo，防止一个券的状态变化影响其他券

  const formatTime = (time: number) => {
    const seconds = String(Math.floor((time / 1000) % 60)).padStart(2, "0");
    const minutes = String(Math.floor((time / (1000 * 60)) % 60)).padStart(2, "0");
    const hours = String(Math.floor((time / (1000 * 60 * 60)) % 24)).padStart(2, "0");
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <>
      {timeLeft > 0 && (
        <div className={`CountdownBar ${className}`}>
          <div className="timeDescText">{timeDescText}</div>
          <div className="box">{hours}</div>
          <div className="colon">:</div>
          <div className="box">{minutes}</div>
          <div className="colon">:</div>
          <div className="box">{seconds}</div>
        </div>
      )}
    </>
  );
}

export default CountdownBar;
