import { useEffect, useState } from "react";
import { UKDialog } from '@/common-components-src/js/server'
import { DialogTitle, DialogContent } from "@mui/material"
import downloadQR from '@/assets/images/downloadQR.png'
import DownloadSVG1 from '@/assets/icons/downloadSVG1.svg'
import DownloadSVG2 from '@/assets/icons/downloadSVG2.svg'
import DownloadSVG3 from '@/assets/icons/downloadSVG3.svg'
import style from './index.module.scss'
import Image from "next/image";

type Props = {
  open: boolean;
  closeFn: any;
};
const DialogStyle = {
  "& .MuiDialog-paper": {
    width: "520px",
    margin: "0",
    borderRadius: "12px",
    background: 'linear-gradient(180deg, #F9F2F2 0%, #FFFFFF 100%)'
  },
  '& .MuiDialogTitle-root': {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
    padding: "20px 24px 12px 24px",
  },
}
const DownloadDialog = ({ open, closeFn }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeHandler = () => {
    setIsOpen(false);
    closeFn && closeFn();
  }

  useEffect(() => {
    setIsOpen(open);
  }, [open])

  return (
    <UKDialog open={isOpen} onClose={closeHandler} showCloseBtn={true} sx={DialogStyle}>
      <DialogTitle>Download Joybuy to get reminders！ </DialogTitle>
      <DialogContent>
        <div className={style.downloadLink}>
          <div className={style.appLink}>
            <a href="https://apps.apple.com/app/joybuy/id6740224837" target="_blank">
              <img className={style.appStore} src="https://st.joy-sourcing.com/website/home/Frame%202036084180.png" alt="appStore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.joybuy.jdi" target="_blank">
              <img className={style.googlePlay} src="https://st.joy-sourcing.com/website/home/Frame%202036084179.png" alt="googlePlay" />
            </a>
          </div>
          <div className={style.qr}>
            <Image className={style.qrImg} src={downloadQR} alt="qrImg" />
            <span className={style.qrText}>Or scan QR code</span>
          </div>
        </div>
        <ul className={style.benefitPoint}>
          <li className={style.item}>
            <div className={style.svg}>
              <DownloadSVG1 />
            </div>
            <span>Price-drop alerts</span>
          </li>
          <li className={style.item}>
            <div className={style.svg}>
              <DownloadSVG2 />
            </div>
            <span>Faster & more secure checkout</span>
          </li>
          <li className={style.item}>
            <div className={style.svg}>
              <DownloadSVG3 width={22} heigth={16} />
            </div>
            <span>Exclusive offers</span>
          </li>
        </ul>
      </DialogContent>
    </UKDialog>
  )
}

export default DownloadDialog;