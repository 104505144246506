"use client"
import { useState } from "react"
import RulesDialog from '@/components/rulesDialog'
import ReservationDialog from '@/components/reservationDialog'
import style from './reservationBtns.module.scss'
import { JDILogin } from "@/lib/jdiClientUtils"
import cookieUtils from "@/utils/cookieUtils"
interface Props {
  offsetTop: number
  reserveTotal: number
}
const ReservationBtns = ({ offsetTop, reserveTotal }: Props) => {
  const [openRulesDialog, setOpenRulesDialog] = useState(false)
  const [openReservationDialog, setOpenReservationDialog] = useState(false)
  //打开规则模态框
  const openRules = () => {
    setOpenRulesDialog(true)
  }
  //打开预约模态框
  const openReservation = () => {
    if(!!cookieUtils.getCookie("mfs_session")){
      setOpenReservationDialog(true)
    }else{
      JDILogin.getInstance().openLoginDialog(() => {});
    }
  }
  return (
    <>
      <div className={style.reservationBtns} style={{ top: offsetTop }}>
        <span className={style.reservation} onClick={() => openReservation()}>Reservation{reserveTotal ? `${reserveTotal > 1 ? 's' : ''}(${reserveTotal})` : ''}</span>
        <span className={style.reservation} onClick={() => openRules()}>Rules</span>
      </div>
      <RulesDialog open={openRulesDialog} closeFn={() => setOpenRulesDialog(false)} helpId={process.env.NEXT_PUBLIC_FLASH_SALE_RULES || '93'} />
      <ReservationDialog open={openReservationDialog} closeFn={() => setOpenReservationDialog(false)} reserveTotal={reserveTotal} />
    </>
  )
}

export default ReservationBtns