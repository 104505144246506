import { cmsConfig as CONFIG } from "@/app/cms/common/cmsConfig";
import { TERMINAL } from "@/constants/cms";
import { jdiColorFetch } from "@/apis/colorFetchV2";
import type { ReservesVo } from "./reservation.interface";

/**
 * 获取热销数据
 * @param params
 * @returns
 */
export const GetReserves = (activityType:number): Promise<ReservesVo> => {
  return new Promise(async (resolve, reject) => {
    const res = await jdiColorFetch("marketing_activity_getReserves", {
      method: "POST",
      headers: CONFIG.colorHeader,
      bodyQuery: {
        activityType
      },
    });
    if (res.code === "200") {
      resolve(res.data);
    } else {
      reject(res);
    }
  });
};