import style from "../../style.module.scss";
import ChevronSvg from "@/assets/icons/chevron-down.svg";


export default function ChevronDown(props: {
    ViewMoreClick: () => void,
    loadingState: string

}) {


    const { ViewMoreClick, loadingState } = props

    const onMoreClick = () => {
        ViewMoreClick()
    }



    return (
        <>
            {loadingState === "loaded" && (
                <div className={style.view_more} onClick={onMoreClick}>
                    View more <ChevronSvg />
                </div>
            )}
            {loadingState === "loading" && (
                <div className={style.loading}>
                    <img className={style.loadingIcon} src="https://img13.360buyimg.com/img/jfs/t1/6467/28/39418/725/671b6244Faa4fd6d8/1e423ef39e48a9e7.png" alt="" />
                    loading...
                </div>
            )}

            {loadingState === "noMore" && (
                // eslint-disable-next-line react/no-unescaped-entities
                <div className={style.noMore}> You've reached the end </div>
            )}
        </>
    )
}