import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const RichTextLoading = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rounded" height={32} width={200} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Stack>
  );
};

export default RichTextLoading;
