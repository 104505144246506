// jd.com

export const cmsConfig = {
    appid: "b2c_web",
    areaCookieKey: "yb_area", // 地址的cookie名称
    verticalTag: "yb_main_cms", // color所需标识
    cartVerticalTag: "cn_ybxt_b2c", // 购物车接口verticalTag
    colorHeader: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-API-Client": "pc",
      "X-API-Login-Type": "3",
      "X-API-Platform": "pc",
      "jnos-product-code": "b2c",
    }
  };
  