/* eslint-disable @next/next/no-img-element */
"use client"
import "./style.scss";
import { formatProductLink } from "@/common-components-src/js/server"
import SafeLink from "@/components/SafeLink";
import { JDIPrice } from "../../../../lib/jdiClientUtils";
import { callAfterLogin } from "@/lib/jdiClientUtils";
import ALLICON from "@/images/icon/all-icon.svg";

export default function Module({ moduleData }: { moduleData: any }) {
  const pageConfig: any = {};

  const initConfig = (moduleData: any) => {
    pageConfig.total = moduleData.externalData?.data?.products?.total;
    pageConfig.products = moduleData.externalData?.data.products.list.slice(0, 5);
    pageConfig.products?.forEach((p: any) => {
      p.url = formatProductLink(p.skuId, p.skuName);
    });
    pageConfig.moreLinks = moduleData.dataBatch?.config?.[0]?.moreLinks?.jumpValue?.link;
    const dataStyle = moduleData.dataStyle;
    pageConfig.containerStyle = {
      marginTop: (dataStyle.style.mt || 0) + "px",
      marginBottom: (dataStyle.style.mb || 0) + "px",
    };
  };

  initConfig(moduleData);

  
  const handlerGotoLand = () => {
    callAfterLogin(() => {
      window.location.href = pageConfig.moreLinks;
    });
  }

  return (
    pageConfig.total > 4 && (
      <div className="uk-pc-newcomer" style={pageConfig.containerStyle}>
        <div className="bar">
          <div className="title"></div>
          <div className="more HOVERRED" onClick={handlerGotoLand}>
            <span style={{ marginRight: "8px" }}>New shopper exclusive</span>
            <ALLICON></ALLICON>
          </div>
        </div>
        <div className="products">
          {pageConfig.products.map((product: any) => (
            // eslint-disable-next-line react/jsx-key
            <div onClick={handlerGotoLand}>
              <div className="product">
                <label>{product.extInfo.salesVolumeDesc}</label>
                <div className="img-box">
                  <div className="mask"></div>
                  <img src={product.imageUrl} />
                </div>
                <JDIPrice symbol="￡" price={product.realPrice} uiType={1} />
                <JDIPrice symbol="￡" price={product.originalPrice} uiType={2} lineThrough containerStyle={{ marginLeft: "8px" }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}
