"use client";
import { useEffect, useRef, useState } from "react";
import "./style.scss";
import Left from "./img/left.svg";
import Right from "./img/right.svg";
import SafeLink from "@/components/SafeLink";

interface TPageConfig {
  time?: number;
  load: boolean;
  indicator?: string;
  top?: string;
  bottom?: string;
  radius?: string;
  backgroundImage?: string;
  showInfoStyle?: any;
  carouselList: Array<any>;
}

export default function CModule({ moduleData, moduleId }: { moduleData: any; moduleId: string }) {
  const pageConfig: TPageConfig = {
    time: 3,
    top: "0px",
    bottom: "0px",
    indicator: "dot",
    radius: "var(--r2, 10px)",
    carouselList: [],
    load: false,
    backgroundImage: "", // 店铺专用
  };
  const [marginPx, setMarginPx] = useState(0);
  const initConfig = (moduleData: any) => {
    const { dataStyle, dataBatch } = moduleData;

    // console.log("dataStyle=====", dataStyle);
    const dataSource = { dataStyle, dataBatch };
    pageConfig.load = true;

    // 增加背景色测试数据
    // const dataBatchNew = []
    // const bgColors = ['rgba(94, 105, 146, 1)', 'rgba(201, 21, 1, 1)','rgba(255, 15, 35, 1)']
    // dataBatch?.carouselList.forEach((v:any, i:any) => {
    //   v.bgColor = bgColors[i]
    // });
    // console.log("dataBatch=====", dataBatch?.carouselList);
    // console.log("link=====", dataBatch?.carouselList[0].jump?.jumpValue);
    //item?.jump?.jumpValue?.link

    Object.assign(pageConfig, {
      time: dataStyle.showInfo.time,
      indicator: dataStyle.showInfo.indicator,
      top: dataStyle.radiusMargin.top + "px",
      bottom: dataStyle.radiusMargin.bottom + "px",
      radius: dataStyle.radiusMargin.radius + "px",
      backgroundImage: dataStyle.showInfo?.image?.imageUrl || "",
      carouselList: dataBatch?.carouselList || [],
    });

    const showInfoStyle = {
      marginTop: pageConfig.top,
      marginBottom: pageConfig.bottom,
      borderRadius: pageConfig.radius,
      backgroundImage: "url(" + pageConfig.backgroundImage + ")",
    };
    pageConfig.showInfoStyle = showInfoStyle;
  };
  initConfig(moduleData);
  let firstImgWidth = pageConfig.carouselList[0].image?.imageWidth;
  let firstImgHeight = pageConfig.carouselList[0].image?.imageHeight;
  const aspectRatio = `${firstImgWidth}/${firstImgHeight}`;
  const paginations = pageConfig.carouselList.length;

  const [current, setCurrent] = useState(0);
  const currentRef = useRef(0);

  const changeSlide = () => {
    if (currentRef.current >= pageConfig.carouselList.length - 1) {
      currentRef.current = 0;
    } else {
      currentRef.current = currentRef.current + 1;
    }
    // (window as any).JDIheader &&  (window as any).JDIheader.changeBackgroundColor({color: '#FF0', transition: false})
    setCurrent(currentRef.current);
  };

  const [firstImgHeightBox, setFirstImgHeightBox] = useState(446 - 166);

  const intervalRef = useRef<any>();

  const updateLeftRightButtonMargin = () => {
    const currentWith = document.body.clientWidth;
    const currentMarginPx = Math.max(currentWith - 1264, 0) / 2;
    setMarginPx(currentMarginPx);
  };

  useEffect(() => {
    // updateLeftRightButtonMargin();
    const clientWidth = document.querySelector(`.uk-pc-carousel`)?.clientWidth || 1190;

    if (pageConfig.carouselList.length) {
      firstImgHeight = firstImgWidth < clientWidth ? firstImgHeight : (clientWidth / firstImgWidth) * firstImgHeight;
      const firstImgHeightBox = firstImgWidth < clientWidth ? firstImgHeight : (clientWidth / firstImgWidth) * firstImgHeight;
      setFirstImgHeightBox(firstImgHeightBox);
    }

    if (pageConfig.carouselList.length > 1) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(changeSlide, (pageConfig.time as number) * 1000);
    }
    window.addEventListener("resize", () => {
      // updateLeftRightButtonMargin();
    });
  }, []);

  const destroyed = () => {
    window.removeEventListener("resize", initConfig);
  };

  const changeLeft = () => {
    if (currentRef.current - 1 < 0) {
      currentRef.current = pageConfig.carouselList.length - 1;
    } else {
      currentRef.current = currentRef.current - 1;
    }
    setCurrent(currentRef.current);
  };

  const changeRight = () => {
    if (currentRef.current + 1 === pageConfig.carouselList.length) {
      currentRef.current = 0;
    } else {
      currentRef.current = currentRef.current + 1;
    }

    setCurrent(currentRef.current);
  };

  const stop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const play = () => {
    intervalRef.current = setInterval(changeSlide, (pageConfig.time as number) * 1000);
  };

  return (
    <div
      className="uk-pc-carousel"
      style={{
        position: "relative",
        top: "-166px",
        marginBottom: "-166px",
      }}
    >
      {pageConfig.load && pageConfig?.carouselList?.length > 0 && (
        <div className="carousel" style={pageConfig.showInfoStyle} onMouseOver={stop} onMouseLeave={play}>
          <ul
            id="lists"
            className="swiper-wrapper"
            style={{
              height: firstImgHeightBox + 166 + "px",
            }}
          >
            {pageConfig.carouselList.map((item, index) => (
              // <li
              //   key={index + "swiper-item"}
              //   className={`swiper-item ${index === current ? "active" : ""}`}
              //   data-jump-data="JSON.stringify(item.jump)"
              //   style={{
              //     height: firstImgHeight + "px",
              //     backgroundImage: item.image && item.image.imageUrl ? "url(" + item.image.imageUrl + ")" : "unset",
              //     cursor: item.jump ? "pointer" : "auto",
              //   }}
              // >
              <li
                key={index + "swiper-item"}
                className={`swiper-item ${index === current ? "active" : ""}`}
                data-jump-data="JSON.stringify(item.jump)"
                style={{
                  height: firstImgHeight + 166 + "px",
                  cursor: item.jump ? "pointer" : "auto",
                  backgroundColor: item.bgColor,
                  paddingTop: "166px",
                }}
              >
                <SafeLink href={item?.jump?.jumpValue?.link ? item?.jump?.jumpValue?.link : "#"} className="img-box">
                  <img src={item.image.imageUrl} className="_img" />
                </SafeLink>
              </li>
            ))}
          </ul>
          {paginations > 1 && (
            <>
              <div className={`paginations paginations-${pageConfig.indicator}`}>
                {[...Array(paginations)].map((v, index) => (
                  <span key={index + "paginations"} className={index === current ? "active" : ""}></span>
                ))}
              </div>
              {/* calc(50% - 12px + 83px) */}
              <div className="left-button" onClick={changeLeft} style={{ left: marginPx, top: "calc(50% - 12px + 83px)" }}>
                <Left />
              </div>
              <div className="right-button" onClick={changeRight} style={{ right: marginPx, top: "calc(50% - 12px + 83px)" }}>
                <Right />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
