"use client";
import { useState } from "react";
import './index.scss';

function Index({ src }: { src: string }) {
  const [showError, setShowError] = useState(false);

  const onImgLoad = () => {
    setShowError(false);
  };

  return (
    <div className="img-container">
      {!showError && (
        <div className="successImg">
          <img src={src} onLoad={onImgLoad} />
        </div>
      )}
      {showError && (
        <div className="errorImg">
          <img src="../../img/default_white.png" />
        </div>
      )}
    </div>
  );
}

export default Index
