"use client";
import React from "react";
import styles from "./tips.module.scss";

export default function SaleTips(labels: any) {
  const displayLabels: any = {};
  if (labels) {
    if (labels.discount) {
      displayLabels.discount = labels.discount;
    } else if (labels.saveX) {
      displayLabels.saveX = labels.saveX;
    }
    if (labels.onlyXLeft) {
      displayLabels.onlyXLeft = labels.onlyXLeft;
    }
  }

  return displayLabels ? (
    <div className={styles.tagGroup}>
      {displayLabels?.discount && <span className={styles.tips1}>{labels?.discount}</span>}
      {displayLabels?.saveX && <span className={styles.tips1}>{labels?.saveX}</span>}
      {displayLabels?.onlyXLeft && <span className={styles.tips2}>{labels?.onlyXLeft}</span>}
      <span className={styles.placeHolder}></span>
    </div>
  ) : (
    <div className={styles.tagGroup}>
      <span className={styles.placeHolder}></span>
    </div>
  );
}
