"use client";

import style from "./style.module.scss";

// ------------引入swiper------------
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore } from "swiper/types";
import "swiper/css";
import Prev from "@/assets/icons/prev.svg";
import Next from "@/assets/icons/next.svg";

import SafeLink from "@/components/SafeLink";
import { useRef, useState } from "react";
import Image from "next/image";
import CDNImage from "@/components/cdnImage/cdnImage";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25223";

export default function Module({ moduleData }: { moduleData: any }) {
  const [isDataReady, setIsDataReady] = useState(false);
  const swiperRef = useRef<SwiperCore>();

  const initConfig = (moduledata: any) => {
    const config = {
      data: moduledata.externalData?.data || [],
      title: moduledata.dataBatch?.title[0].title || "",
    };
    return config;
  };

  const pageConfig = initConfig(moduleData);
  if (!pageConfig.data || pageConfig.data.length < 6) return null;

  return (
    <div className={style.bg}>
      <div className={style["uk-pc-popular-categoriesV2"]}>
        <div className={style.title}>{pageConfig.title}</div>
        <Swiper
          slidesPerView={6}
          spaceBetween={24}
          loop={true}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className={style.mySwiper}
          style={{
            visibility: isDataReady ? "visible" : "hidden",
          }}
          onSwiper={() => {
            setIsDataReady(true);
          }}
        >
          {pageConfig.data.map((category: any, index: any) => (
            <SwiperSlide key={index}>
              <div
                className={style.slideItem}
                onClick={() =>
                  sendClickTrack.Home_Popular_Categories({
                    index: index,
                    third_category_name: "-100",
                    second_category_name: "-100",
                    first_category_name: "-100",
                  })
                }
                exptag={"exp|" + EXPOSURE_RECORD.Home_Popular_Categories_Expo}
                data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                data-exptag-json-param={JSON.stringify({
                  index: index,
                  third_category_name: "-100",
                  second_category_name: "-100",
                  first_category_name: "-100",
                })}
              >
                <SafeLink href={`/s?k=${category?.cateName}&l3=${category?.cateId}`} target="_blank">
                  <CDNImage src={category?.cateImageUrl || ""} width={360} height={360} alt={category?.cateName} />
                  <p>{category?.cateName}</p>
                </SafeLink>
              </div>
            </SwiperSlide>
          ))}

          {pageConfig.data.length > 5 && (
            <div className={style.buttonGroup}>
              <button className={style.prev} onClick={() => swiperRef.current?.slidePrev()}>
                <Prev />
              </button>
              <button className={style.next} onClick={() => swiperRef.current?.slideNext()}>
                <Next />
              </button>
            </div>
          )}
        </Swiper>
      </div>
    </div>
  );
}
