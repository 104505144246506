"use client";
import style from "./style.module.scss";
import { getUserUUIDFromCookie } from "@/utils/userUtils";
import { useRef, useState, useEffect } from "react";
import { AddCart } from "@/apis/service";
import { queryFeeds } from "@/apis/land/land.api";
import UKToast from "@/components/UKToast";
import { formatProductLink } from "@/common-components-src/js/server";
import { getRichText } from "@/app/cms/common";
import ChevronDown from "@/assets/icons/chevron-down.svg";
import Fourth from "@/assets/icons/Fourth.svg";
import SecondRunnerUp from "@/assets/icons/SecondRunnerUp.svg";
import RunnerUp from "@/assets/icons/RunnerUp.svg";
import Champion from "@/assets/icons/Champion.svg";
import { JDIProductCardV2 } from "@/lib/jdiClientUtils";
import { getPromotionObj } from "@/utils/tracksUtils";
import { sendPv, sendClickTrack, EXPOSURE_RECORD } from "@/tracks/Activity";

const currency = "￡";
export const Content = (props: any) => {
  const { skuList, dataExternalKey, paramsData, isBestSeller, seo } = props;

  const [cuPage, setCuPage] = useState(2);
  const lastScrollIdRef = useRef<number>(skuList[skuList.length - 1]?.extInfo?.scrollId);
  const [productList, setProductList] = useState<any[]>([...skuList]);
  const [stopGetProduct, setStopGetProduct] = useState(skuList?.[skuList.length - 1]?.extInfo?.lastSkuTag || false); //是否停止获取数据
  const [loading, setLoading] = useState(false); //是否在加载数据
  const [isShowTryAgain, setIsShowTryAgain] = useState(false); //是否展示tryAgain
  const eventTrackingId = seo?.pageTrackingId || "";

  const commentScore = (skuInfo: any): string => {
    return skuInfo?.extInfo?.commentScore || "4.9";
  };

  //------------------------ 商品&加购 点击和曝光 埋点 -------------------------

  const genJsonParam = (product: any, index: number) => {
    let promotion;
    if (product && product?.extInfo && product?.extInfo?.labels && product?.extInfo?.labels?.labelData) {
      const obj = product?.extInfo?.labels?.labelData;
      promotion = Object.entries(obj).map(([key, value]) => ({
        key,
        ...value,
      }));
    }
    const json_param: any = {
      skuid: isNaN(product?.skuId) ? "-100" : product.skuId.toString(),
      firprice: isNaN(product?.realPrice) ? -100 : Number(product?.realPrice),
      firpricetype: "11",
      secprice: isNaN(product?.originalPrice) ? -100 : Number(product?.originalPrice),
      secpricetype: "83",
      index: index,
      freqTag: "-100",
      ...getPromotionObj(promotion),
    };
    return json_param;
  };
  const genEventTrackingInfo = (product: any, index: number) => {
    const eventTrackingInfo = {} as { [key: string]: any };
    const json_param = genJsonParam(product, index);
    const expConfig = {
      useClick: false,
      stay_duration: 0.3,
      repeated: false,
      area_rate: 0.5,
    };
    eventTrackingInfo["productClickPoint"] = () => sendClickTrack.Product(eventTrackingId, json_param);
    eventTrackingInfo["cartClickPoint"] = () => sendClickTrack.AddToCart(eventTrackingId, json_param);
    eventTrackingInfo["productExpConfig"] = {
      event_id: `${eventTrackingId}${EXPOSURE_RECORD.ProductExpo}`,
      config: expConfig,
      json_param: json_param,
    };

    return eventTrackingInfo;
  };

  // ------------------------------- 上报pv埋点 -----------------------------------
  useEffect(() => {
    sendPv.Activity(eventTrackingId);
  }, [productList]);

  const getProduct = async () => {
    if (stopGetProduct || loading) {
      return;
    }

    setLoading(true);
    setIsShowTryAgain(false);
    //最大商品数量减去已获取的商品，如果小于20则赋值给pageSize
    // 构建请求参数
    let _params = {
      type: dataExternalKey,
      cuPage: cuPage,
      pageSize: 20,
      mapExInfo: {
        ...paramsData,
        lastScrollId: lastScrollIdRef.current,
      },
    };
    // 如果没有缓存数据，请求推荐商品
    await queryFeeds(_params)
      .then((res) => {
        console.log("sxt-res", res);
        const list = res.data.map((sku: any, index) => {
          sku.url = formatProductLink(sku.skuId, sku.skuName);
          if (index === res.data.length - 1) {
            lastScrollIdRef.current = sku?.extInfo?.scrollId;
            setStopGetProduct(sku?.extInfo?.lastSkuTag || false);
          }
          return sku;
        });
        setProductList([...productList, ...list]);
        setCuPage(cuPage + 1);
      })
      .catch((e) => {
        console.log("sxt-error", e);
        //判断首次加载失败，则展示tryAgain
        if (productList.length === 0) {
          setIsShowTryAgain(true);
        }
      });
    setLoading(false);
  };

  /**
   * 加车
   * https://cf.jd.com/pages/viewpage.action?pageId=605887939
   */
  const addCart = async (product: any) => {
    const cartuuid = getUserUUIDFromCookie();
    const res = await AddCart({ num: 1, skuId: product.skuId, cartuuid });
    if (res?.code === "200") {
      UKToast.show({
        content: "Added to Cart!", // 弹窗内容
        icon: "success", // 可选：success, fail, loading, warn, info，影响图标
      });
      (window as any).JDIheader.initCartNum();
    } else {
      UKToast.show({
        content: "Added to cart Failed please try again", // 弹窗内容
        icon: "error", // 可选：success, fail, loading, warn, info，影响图标
      });
    }
  };

  const getSaleNum = (labels: any) => {
    if (!labels) {
      return "";
    }
    const { labelKeys, labelData } = labels;
    if (labelKeys.includes("15")) {
      return labelData["15"].value;
    }
    return "";
  };

  const getTopTag = (index: number) => {
    if (index < 10) {
      return (
        <div className={style.topTagBox}>
          {index === 0 ? (
            <Champion className={style.topTagIcon} />
          ) : index === 1 ? (
            <RunnerUp className={style.topTagIcon} />
          ) : index === 2 ? (
            <SecondRunnerUp className={style.topTagIcon} />
          ) : (
            <Fourth className={style.topTagIcon} />
          )}
          <span className={style.topTagNum}>{index + 1}</span>
        </div>
      );
    }
  };

  return (
    <div className={style["uk-pc-product-land"]}>
      {isShowTryAgain ? (
        <div className={style.errorDefault}>
          <img className={style.errorImg} src="https://img11.360buyimg.com/img/jfs/t1/210493/37/43002/2927/672232c4Fce0f1f03/b5f210e659fd102c.png" alt="" />
          <p className={style.errorText}>Error, please try again</p>
          <div className={style.tryAgain} onClick={() => getProduct()}>
            Try again
          </div>
        </div>
      ) : (
        <div className={style.w}>
          <div className={style.productList}>
            {productList.length
              ? productList.map((item, index) => {
                  const productData = {
                    type: "2",
                    contentStyle: { marginTop: "16px" },
                    skuName: item.skuName,
                    skuImgKeyParams: {
                      imgUrlKey: item.imageUrl,
                      width: 240,
                      height: 240,
                    },
                    skuId: item.skuId,
                    skuImgStyle: { height: "240px" },
                    realPrice: item.realPrice,
                    originalPrice: item.originalPrice,
                    currency: currency,
                    imgTips: isBestSeller
                      ? {
                          position: "topLeft",
                          tipDom: getTopTag(index),
                        }
                      : null,
                    shouldShowScore: true,
                    score: commentScore(item),
                    saleNum: getSaleNum(item.extInfo?.labels),
                    shouldShowCart: true,
                    onClickCart: addCart,
                    promotions: Object.values(item?.extInfo?.labels?.labelData || {}),
                    trackingPointParams: genEventTrackingInfo(item, index),
                  };

                  return <JDIProductCardV2 productData={productData} key={item.skuId} />;
                })
              : null}
          </div>
          {loading ? (
            <div className={style.loading}>
              <img className={style.loadingIcon} src="https://img13.360buyimg.com/img/jfs/t1/6467/28/39418/725/671b6244Faa4fd6d8/1e423ef39e48a9e7.png" alt="" />
              loading...
            </div>
          ) : stopGetProduct ? (
            <div className={style.reached}>You've reached the end</div>
          ) : (
            <div className={style.more} onClick={() => getProduct()}>
              View more
              <ChevronDown className={style.icon} style={{ color: "#000" }} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
