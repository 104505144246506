"use client";
import { use, useEffect, useRef, useState } from "react";
import { AddCart } from '@/apis/service'
import "./index.scss";
import UKToast from "@/components/UKToast";
import { getUserUUIDFromCookie } from "@/utils/userUtils";
import { formatProductLink } from "@/common-components-src/js/server"
import SafeLink from "@/components/SafeLink";
import ALLICON from "@/images/icon/all-icon.svg"
import CARICON from "@/images/icon/car-icon.svg"
import { JDIPrice } from "../../../../lib/jdiClientUtils";

export default function Content({ skuList, landUrl }: any) {
  const productList = skuList.map((item: any) => {
    item.url = formatProductLink(item.skuId, item.skuName) || '#';
    return item
  });
  // console.log(productList, "----");
  const cartuuidRef = useRef<string>('');

  useEffect(() => {
    cartuuidRef.current = getUserUUIDFromCookie()
  }, []);

  /**
   * 加车
   * https://cf.jd.com/pages/viewpage.action?pageId=605887939
   */
  const addCart = async (skuId: string) => {
    const cartuuid = cartuuidRef.current;
    const res = await AddCart({ num: 1, skuId, cartuuid })
    // const res = await fetch("/se_addCart", {
    //   method: "post",
    //   bodyQuery: {
    //     area: "659011275_659011342_0_0",
    //     verticalTag: "cn_ybxt_b2c",
    //     client: "pc",
    //     clientVersion: "1.0.0",
    //     scene: 0, //  1 预售 2 拼团
    //     operations: {
    //       products: [
    //         {
    //           itemType: 1, //  1 单品 4
    //           num: 1, //productNumber,
    //           skuId: skuId,
    //         },
    //       ],
    //     },
    //     cartuuid: cartuuidRef.current, // "0cef7cf0-933a-4837-9c7c-602322c28ed7",
    //     userActionId: "addCart",
    //   },
    // });    
    if(res?.success){
      UKToast.show({
        content: "Added to Cart!", 
        icon: "success", 
      });
      (window as any).JDIheader.initCartNum() 
    }else{
      UKToast.show({
        content: res?.message, 
        icon: "error", 
      });
    }
  };

  const handleImageError = (e: any) => {
    console.log('handleImageError', e);
    e.target.src = "https://img14.360buyimg.com/img/jfs/t1/191107/38/49975/754/671cbf19F227be05c/5b8b2d6d530328e5.png";
  }

  // 渲染优惠券卡片
  const couponCardRender = (item: any) => {
    return (
      <SafeLink href={item.url || '#'} key={item.skuId} title={item.skuName}>
        <div key={JSON.stringify(item)} className="product FloatingLayer">
          <div className="_content">
            <div className="_img_box">
              <img src={item.imageUrl} alt={item.skuName} onError={handleImageError} className="_img" />
            </div>
            <div className="title">{item.skuName}</div>
            <div className="price-box">
              <div className="price-floor">
                <JDIPrice symbol="￡" price={item.realPrice} uiType={1} />
                {
                  Number(item.originalPrice) > Number(item.realPrice) ?
                    <JDIPrice symbol="￡" price={item.originalPrice} uiType={2} lineThrough containerStyle={{ marginLeft: "8px" }} /> : null
                }
              </div>
              <div className="add-cart HOVERCAR" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation()
                addCart(item.skuId)
              }}>
                <CARICON ></CARICON>
              </div>
            </div>
          </div>
        </div>
      </SafeLink>
    );
  };

  return (
    <div className="uk-pc-new-arrival">
      <div>
        <div className="module-title">
          <div className="module-title-text"></div>
          <SafeLink href={landUrl} className="HOVERRED">
            <div className="module-titl-all">
              <span style={{ marginRight: "8px" }}>All</span>
              {/* <i className="module-title-right"></i> */}
              <ALLICON></ALLICON>
            </div>
          </SafeLink>

        </div>
      </div>
      <div>{productList?.length ? <div className="productList-box">{productList.map((item: any) => couponCardRender(item))}</div> : null}</div>
    </div>
  );
}
