// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25223
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IPVTrackParamsRecord {
  /** 首页 */
Home_Main: undefined
}

const sendPv = {
  /** PV-首页 */
Home_Main: () => {
    eventTrackingUtils.submitPV({page_id:'Home_Main'});
  },
}

interface IExpoTrackParamsRecord {
  /** Banner轮播曝光 */
Home_Banner_Expo: {
/** 位置  */
index: number}
  /** 新人楼层曝光 */
Home_Welcome_Expo: {
/** 商品id  */
skuid: string,
/** 位置  */
index: number}
  /** 促销秒杀曝光 */
Home_Pro_FlashSales_Expo: {
/** 商品id  */
skuid: string,
/** 位置  */
index: number}
  /** 营销活动聚合曝光 */
Home_Pro_Activity_Expo: {
/** 位置  */
index: number,
/** 标题  */
title: string}
  /** 热门分类曝光 */
Home_Popular_Categories_Expo: {
/** 位置  */
index: number,
/** 商品三级类目名称  */
third_category_name: string,
/** 商品二级类目名称  */
second_category_name: string,
/** 商品一级类目名称  */
first_category_name: string}
  /** 新品曝光 */
Home_Pro_NewArrivals_Expo: {
/** 商品id  */
skuid: string,
/** 位置  */
index: number}
  /** BestSellers曝光 */
Home_Pro_BestSellers_Expo: {
/** 标题  */
title: string,
/** 商品一级类目名称  */
first_category_name: string,
/** 商品二级类目名称  */
second_category_name: string,
/** 商品三级类目名称  */
third_category_name: string}
  /** 新人楼层查看更多曝光 */
Home_Welcome_More_Expo: undefined
  /** 促销秒杀查看更多曝光 */
Home_Pro_FlashSales_More_Expo: undefined
  /** BestSellers查看更多曝光 */
Home_Pro_BestSellers_More_Expo: undefined
  /** 促销新品查看更多曝光 */
Home_Pro_NewArrivals_More_Expo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** Banner轮播曝光 */
  Home_Banner_Expo:'Home_Banner_Expo',
/** 新人楼层曝光 */
  Home_Welcome_Expo:'Home_Welcome_Expo',
/** 促销秒杀曝光 */
  Home_Pro_FlashSales_Expo:'Home_Pro_FlashSales_Expo',
/** 营销活动聚合曝光 */
  Home_Pro_Activity_Expo:'Home_Pro_Activity_Expo',
/** 热门分类曝光 */
  Home_Popular_Categories_Expo:'Home_Popular_Categories_Expo',
/** 新品曝光 */
  Home_Pro_NewArrivals_Expo:'Home_Pro_NewArrivals_Expo',
/** BestSellers曝光 */
  Home_Pro_BestSellers_Expo:'Home_Pro_BestSellers_Expo',
/** 新人楼层查看更多曝光 */
  Home_Welcome_More_Expo:'Home_Welcome_More_Expo',
/** 促销秒杀查看更多曝光 */
  Home_Pro_FlashSales_More_Expo:'Home_Pro_FlashSales_More_Expo',
/** BestSellers查看更多曝光 */
  Home_Pro_BestSellers_More_Expo:'Home_Pro_BestSellers_More_Expo',
/** 促销新品查看更多曝光 */
  Home_Pro_NewArrivals_More_Expo:'Home_Pro_NewArrivals_More_Expo',
})

interface IClickTrackParamsRecord {
  /** Banner轮播点击 */
Home_Banner: {
/** 位置  */
index: number}
  /** 新人楼层点击 */
Home_Welcome: {
/** 位置  */
index: number,
/** 商品id  */
skuid: string}
  /** 促销秒杀点击 */
Home_Pro_FlashSales: {
/** 位置  */
index: number,
/** 商品id  */
skuid: string}
  /** 营销活动聚合点击 */
Home_Pro_Activity: {
/** 标题  */
title: string,
/** 位置  */
index: number}
  /** 热门分类点击 */
Home_Popular_Categories: {
/** 位置  */
index: number,
/** 商品三级类目名称  */
third_category_name: string,
/** 商品二级类目名称  */
second_category_name: string,
/** 商品一级类目名称  */
first_category_name: string}
  /** 新品点击 */
Home_Pro_NewArrivals: {
/** 位置  */
index: number,
/** 商品id  */
skuid: string}
  /** BestSellers点击 */
Home_Pro_BestSellers: {
/** 标题  */
title: string,
/** 商品一级类目名称  */
first_category_name: string,
/** 商品二级类目名称  */
second_category_name: string,
/** 商品三级类目名称  */
third_category_name: string}
  /** 新人楼层查看更多点击 */
Home_Welcome_More: undefined
  /** 促销秒杀查看更多点击 */
Home_Pro_FlashSales_More: undefined
  /** BestSellers查看更多点击 */
Home_Pro_BestSellers_More: undefined
  /** 促销新品查看更多点击 */
Home_Pro_NewArrivals_More: undefined
}

const sendClickTrack = {
  /** 点击事件上报-Banner轮播点击 */
Home_Banner: (json_param: IClickTrackParamsRecord['Home_Banner']) => {
    eventTrackingUtils.submitCL({event_id:'Home_Banner',json_param});
  },
  /** 点击事件上报-新人楼层点击 */
Home_Welcome: (json_param: IClickTrackParamsRecord['Home_Welcome']) => {
    eventTrackingUtils.submitCL({event_id:'Home_Welcome',json_param});
  },
  /** 点击事件上报-促销秒杀点击 */
Home_Pro_FlashSales: (json_param: IClickTrackParamsRecord['Home_Pro_FlashSales']) => {
    eventTrackingUtils.submitCL({event_id:'Home_Pro_FlashSales',json_param});
  },
  /** 点击事件上报-营销活动聚合点击 */
Home_Pro_Activity: (json_param: IClickTrackParamsRecord['Home_Pro_Activity']) => {
    eventTrackingUtils.submitCL({event_id:'Home_Pro_Activity',json_param});
  },
  /** 点击事件上报-热门分类点击 */
Home_Popular_Categories: (json_param: IClickTrackParamsRecord['Home_Popular_Categories']) => {
    eventTrackingUtils.submitCL({event_id:'Home_Popular_Categories',json_param});
  },
  /** 点击事件上报-新品点击 */
Home_Pro_NewArrivals: (json_param: IClickTrackParamsRecord['Home_Pro_NewArrivals']) => {
    eventTrackingUtils.submitCL({event_id:'Home_Pro_NewArrivals',json_param});
  },
  /** 点击事件上报-BestSellers点击 */
Home_Pro_BestSellers: (json_param: IClickTrackParamsRecord['Home_Pro_BestSellers']) => {
    eventTrackingUtils.submitCL({event_id:'Home_Pro_BestSellers',json_param});
  },
  /** 点击事件上报-新人楼层查看更多点击 */
Home_Welcome_More: () => {
    eventTrackingUtils.submitCL({event_id:'Home_Welcome_More'});
  },
  /** 点击事件上报-促销秒杀查看更多点击 */
Home_Pro_FlashSales_More: () => {
    eventTrackingUtils.submitCL({event_id:'Home_Pro_FlashSales_More'});
  },
  /** 点击事件上报-BestSellers查看更多点击 */
Home_Pro_BestSellers_More: () => {
    eventTrackingUtils.submitCL({event_id:'Home_Pro_BestSellers_More'});
  },
  /** 点击事件上报-促销新品查看更多点击 */
Home_Pro_NewArrivals_More: () => {
    eventTrackingUtils.submitCL({event_id:'Home_Pro_NewArrivals_More'});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
  sendPv,
}
export type {
  IExpoTrackParamsRecord,
}