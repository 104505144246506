"use client";

import React, { useMemo, useState, useRef, useEffect } from "react";
import "./CouponList.scss";
import { ECouponState, ECouponType, ELayoutType, ICouponInfo } from "../type";
import ScrollContainer, { ScrollContainerRef } from "./ScrollContainer";
import { useStores } from "@/models";

import CouponContent from "./CouponContent";
import CountdownBar from "./CountdownBar";
import Watermark from "./Watermark";
import SVGScrollController from "../icons/SVGScrollController.svg";

import { countdownCoupons } from "./TestCases";
import { testCoupons } from "./TestCases";
import { observer } from "mobx-react";

interface CouponListProps {
  countdownEnable: boolean;
  layoutType: ELayoutType;
  currentMills: number;
  initialCouponInfos: ICouponInfo[];
}

const CouponList: React.FC<CouponListProps> = observer(({ countdownEnable, layoutType, currentMills, initialCouponInfos }) => {
  // WARNING: test mocks
  // const [couponInfos, setCouponInfos] = useState<ICouponInfo[]>(testCoupons);
  // const [couponInfos, setCouponInfos] = useState<ICouponInfo[]>(countdownCoupons);

  const { cmsUkCouponStore } = useStores();
  const couponListId = (() => {
    return initialCouponInfos.map(({ batchId }) => batchId).join("-");
  })();

  useEffect(() => {
    if (!cmsUkCouponStore.couponListMap.get(couponListId)?.length) {
      cmsUkCouponStore.setCouponInfos(couponListId, initialCouponInfos);
    }
  }, []);

  const setCouponInfo = (updatedCoupon: ICouponInfo) => {
    cmsUkCouponStore.setCouponInfo(couponListId, updatedCoupon);
  };

  const scrollContainerRef = useRef<ScrollContainerRef>(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const layoutClassName = useMemo(() => {
    return (
      {
        [ELayoutType.OnePerRow]: "OnePerRow",
        [ELayoutType.TwoPerRow]: "TwoPerRow",
        [ELayoutType.MultiplePerRow]: "MultiplePerRow",
      }[layoutType] || ""
      // }[ELayoutType.OnePerRow] || "" // mock
    );
  }, [layoutType]);

  const getCouponTypeClassName = useMemo(() => {
    return ({ couponType, couponState }: ICouponInfo) => {
      if ([ECouponState.AllClaimed, ECouponState.Expired].includes(couponState)) {
        return "Disabled";
      }
      if (ECouponType.Off === couponType) {
        return "Off";
      }
      if (ECouponType.ShippingOff === couponType) {
        return "ShippingOff";
      }
      return "";
    };
  }, []);

  const scrollContainerLeft = () => {
    scrollContainerRef.current?.scrollContainer("left");
  };
  const scrollContainerRight = () => {
    scrollContainerRef.current?.scrollContainer("right");
  };

  return (
    <div className={"Wrapper"}>
      {canScrollLeft && <SVGScrollController className={"ScrollContainerControllerLeft"} onClick={scrollContainerLeft} />}
      {canScrollRight && <SVGScrollController className={"ScrollContainerControllerRight"} onClick={scrollContainerRight} />}
      <ScrollContainer
        ref={scrollContainerRef}
        scrollDistancePatch={16}
        className={`CouponList`}
        setCanScrollLeft={setCanScrollLeft}
        setCanScrollRight={setCanScrollRight}
      >
        {cmsUkCouponStore.couponListMap?.get(couponListId)?.map((couponInfo) => (
          <li className={`CouponItem ${layoutClassName}  ${getCouponTypeClassName(couponInfo)}`} key={couponInfo.batchId}>
            <CouponContent couponInfo={couponInfo} setCouponInfo={setCouponInfo} />
            {countdownEnable && <CountdownBar currentMills={currentMills} couponInfo={couponInfo} setCouponInfo={setCouponInfo} />}
            <Watermark couponState={couponInfo.couponState} couponGetState={couponInfo.couponGetState} />
            <div className="LeftPrefix" />
          </li>
        ))}
      </ScrollContainer>
    </div>
  );
});

export default CouponList;
