"use client";
import { useState } from "react";
import style from "../../style.module.scss";
import { formatProductLink } from "@/common-components-src/js/server";
import { roundToTwoDecimals, formatPriceWithoutSymbol } from "@/utils/priceUtils";
import { JDIPrice } from "../../../../../../lib/jdiClientUtils";
import SafeLink from "@/components/SafeLink";
import { eventTrackingUtils } from "@/utils/eventTrackingUtils";
import { sendClickTrack, EXPOSURE_RECORD, type IExpoTrackParamsRecord } from "@/tracks/25159";
import { getPromotionObj } from "@/utils/tracksUtils";

// assets\icons
export default function ProductList(props: { productList: any; isNewcomerFlag: boolean }) {
  const { productList, isNewcomerFlag } = props;
  const currency = "￡";
  const comparePrice = (originalPrice: string, realPrice: string): boolean => {
    return Number(realPrice) < Number(originalPrice);
  };

  const genJsonParam = (product: any, index: number) => {
    let promotion;
    if (product && product?.extInfo && product?.extInfo?.labels && product?.extInfo?.labels?.labelData) {
      const obj = product?.extInfo?.labels?.labelData;
      promotion = Object.entries(obj).map(([key, value]) => ({
        key,
        ...value,
      }));
    }
    const json_param: any = {
      skuid: isNaN(product?.skuId) ? "-100" : product.skuId.toString(),
      firprice: isNaN(product?.realPrice) ? -100 : Number(product?.realPrice),
      firpricetype: "11",
      secprice: isNaN(product?.originalPrice) ? -100 : Number(product?.originalPrice),
      secpricetype: "83",
      index: index,
      freqTag: "-100",
      ...getPromotionObj(promotion),
    };
    return json_param;
  };

  const handleClickBuyNow = (item: any, index: number) => {
    sendClickTrack.Activity_Discount_BuyNow(genJsonParam(item, index));
  };
  const handleClickProduct = (item: any, index: number) => {
    sendClickTrack.Activity_Discount_Product(genJsonParam(item, index));
  };

  return (
    <div className={style.content_wrap}>
      {productList && productList.length > 0
        ? productList.map((item: any, index: number) => {
            return (
              <div key={index} className={style.content_item}>
                <SafeLink href={formatProductLink(item.skuId, item.skuName)} key={item.skuId}>
                  <div
                    className={style.image_box}
                    exptag={"exp|" + EXPOSURE_RECORD.Activity_Discount_ProductExpo}
                    data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.5}'
                    data-exptag-json-param={JSON.stringify(genJsonParam(item, index))}
                    onClick={() => handleClickProduct(item, index)}
                  >
                    <img src={item.imageUrl} alt="" />
                  </div>
                </SafeLink>
                <div className={style.content_item_main}>
                  <div className={style.item_main_title}>
                    <SafeLink href={formatProductLink(item.skuId, item.skuName)} key={item.skuId}>
                      {item.skuName}
                    </SafeLink>
                  </div>
                  <div className={style.item_main_amount_money_box}>
                    {/* isNewcomerFlag */}
                    {isNewcomerFlag ? (
                      <>
                        <JDIPrice
                          symbol={currency}
                          price={item.realPrice}
                          uiType={1}
                          containerStyle={{ whiteSpace: "nowrap", marginRight: "8px", color: `#FF0F23`, lineHeight: "21px" }}
                          integerStyle={{ fontSize: "24px" }}
                          decimalPlaceStyle={{ fontSize: "15px" }}
                          symbolStyle={{ fontSize: "14px" }}
                        />
                        {item.originalPrice && comparePrice(item.originalPrice, item.realPrice) && (
                          <JDIPrice
                            symbol={currency}
                            price={item.originalPrice}
                            uiType={2}
                            lineThrough
                            containerStyle={{ whiteSpace: "nowrap", height: "22px", lineHeight: "20px", fontSize: "14px" }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <JDIPrice
                          symbol={currency}
                          price={item.originalPrice}
                          uiType={1}
                          containerStyle={{ whiteSpace: "nowrap", marginRight: "8px", color: `#FF0F23`, lineHeight: "21px" }}
                          integerStyle={{ fontSize: "24px" }}
                          decimalPlaceStyle={{ fontSize: "15px" }}
                          symbolStyle={{ fontSize: "14px" }}
                        />
                      </>
                    )}
                  </div>
                  {!item.stock ? (
                    <div className={style.item_out_stock_btn}>Out of stock</div>
                  ) : (
                    <SafeLink href={formatProductLink(item.skuId, item.skuName)} key={item.skuId}>
                      <div className={style.item_buy_now_btn} onClick={() => handleClickBuyNow(item, index)}>
                        Buy now
                      </div>
                    </SafeLink>
                  )}
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
}
