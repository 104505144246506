import styles from "@/app/promo/best-seller/page.module.scss";
import { getUrlPathName } from "@/utils/stringUtils";

export const getRankIcon = (index: number) => {
  if (index >= 10) {
    return null;
  }

  const rankClasses: { [key: number]: string } = {
    0: styles.rankIcon1,
    1: styles.rankIcon2,
    2: styles.rankIcon3,
  };

  const baseClass = styles.rankIcon; // 基础样式类
  const dynamicClass = rankClasses[index] || styles.rankIconMore;
  const className = [baseClass, dynamicClass].join(" ");

  return <div className={className}>{index + 1}</div>;
};

export const goToCurrentRankPage = (sequenceId: number, categoryTabName: string) => {
  location.href = `/promo/best-seller/${getUrlPathName(categoryTabName.toLowerCase())}/${sequenceId}`;
};
