export interface ICouponModuleDataBatch {
  dataSource: {
    coupon: ICouponInfo;
  }[];
  countdownRule: { countdown: boolean }[];
  displayRule: { layout: ELayoutType }[];
}

// 布局枚举
export enum ELayoutType {
  /**
   * 一行一个
   */
  OnePerRow = 1,
  /**
   * 一行两个
   */
  TwoPerRow = 2,
  /**
   * 一行多个
   */
  MultiplePerRow = 3,
}

// 优惠券信息接口
export interface ICouponInfo {
  /**
   * eg：全品类可用/限购服装商品
   */
  couponUseLimit?: string;

  /**
   * 是否已删除
   */
  deleted?: boolean;

  /**
   * 1=未开始，2=进行中，3=已过期，4=已抢光
   */
  couponState: ECouponState;

  /**
   * 0=未领取，1=待使用，2=已使用，或者已过期
   */
  couponGetState: ECouponGetState;

  /**
   * 优惠券批次ID
   */
  batchId: number;

  /**
   * 优惠券活动ID
   */
  activityId?: number;

  /**
   * 6->M天生效N天有效的券
   * 1->N天有效的券
   * 5->现有的固定时间
   */
  expireType?: EExpireType;

  /**
   * 活动开始时间
   */
  activityBeginTime: number;

  /**
   * 活动结束时间
   */
  activityEndTime: number;

  /**
   * 优惠券ID
   */
  couponId?: string;

  /**
   * 优惠券名称
   */
  name?: string;

  /**
   * roleId ruleId
   */
  roleId?: string;

  /**
   * encryptedKey
   */
  encryptedKey?: string;

  /**
   * putKey
   */
  putKey?: string;

  /**
   * 1=优惠券，2=运费券
   */
  couponType?: ECouponType;

  /**
   * 店铺券绑定类型 1、全店铺，2、限定商品，3、限定某些商品不参加
   * 中台枚举值较多，可以处理为1、全店铺，非1限定商品
   */
  bindType?: EBindType;

  /**
   * 促销类型，1=现金券,2=折扣券
   */
  couponPromotionType?: ECouponPromotionType;

  /**
   * 使用门槛：eg：满200可用
   */
  usingThreshold?: number;

  /**
   * 优惠券面额
   * 满减为金额；
   * 满折： 1.优惠折扣8折，discount值0.8
   */
  discount?: number;

  /**
   * 优惠券类型 0、京券，1、东券
   */
  type?: ECouponKind;

  /**
   * 发放类型 1.随促销发放 2.推送 3.领取 4.京豆换券
   */
  grantType?: EGrantType;

  /**
   * 优惠限额
   */
  quota?: number;

  /**
   * 有效期开始时间
   */
  beginTime?: number;

  /**
   * 有效期开始时间：yyyy.MM.dd
   */
  beginTimeStr?: string;

  /**
   * 有效期结束时间
   */
  endTime?: number;

  /**
   * 有效期截止时间：yyyy.MM.dd
   */
  endTimeStr?: string;
}

// 优惠券状态枚举
export enum ECouponState {
  NotStarted = 1,
  InProgress = 2,
  Expired = 3,
  AllClaimed = 4,
}

// 优惠券领取状态枚举
export enum ECouponGetState {
  NotReceived = 0,
  PendingUse = 1,
  UsedOrExpired = 2,
}

// 过期类型枚举
export enum EExpireType {
  MDayValidNDay = 6,
  NDayValid = 1,
  FixedTime = 5,
}

// 优惠券类型枚举
export enum ECouponType {
  /** 优惠券 */
  Off = 1,
  /** 运费券 */
  ShippingOff = 2,
}

// 店铺券绑定类型枚举
enum EBindType {
  AllStore = 1,
  LimitedGoods = 2,
  ExcludedGoods = 3,
}

// 促销类型枚举
enum ECouponPromotionType {
  CashCoupon = 1,
  DiscountCoupon = 2,
}

// 优惠券种类枚举
enum ECouponKind {
  JingCoupon = 0,
  DongCoupon = 1,
}

// 发放类型枚举
enum EGrantType {
  WithPromotion = 1,
  Push = 2,
  Receive = 3,
  BeanExchange = 4,
}
