"use client";

import React, { useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import "./ScrollContainer.scss";

interface ScrollContainerProps {
  children: React.ReactNode;
  className: string;
  scrollDistancePatch: number; // 滚动距离补丁，由于item之间有margin间隔，需要多滚动一个间隔的距离，保证能对齐边缘
  setCanScrollLeft: React.Dispatch<React.SetStateAction<boolean>>;
  setCanScrollRight: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ScrollContainerRef {
  scrollContainer: (direction: "left" | "right") => void;
}

const ScrollContainer = forwardRef<ScrollContainerRef, React.PropsWithChildren<ScrollContainerProps>>(
  ({ children, className, setCanScrollLeft, setCanScrollRight, scrollDistancePatch = 0 }, ref) => {
    const scrollContainerRef = useRef<HTMLUListElement | null>(null);

    useImperativeHandle(ref, () => ({
      scrollContainer: (direction: "left" | "right") => {
        if (!scrollContainerRef.current) {
          return;
        }
        const absDistance = scrollContainerRef.current.clientWidth + scrollDistancePatch;
        scrollContainerRef.current.scrollBy({
          top: 0,
          left: direction === "right" ? absDistance : -absDistance,
          behavior: "smooth",
        });
      },
    }));

    const setScrollable = useCallback(() => {
      if (!scrollContainerRef.current) {
        return;
      }

      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth - 1);
    }, [setCanScrollLeft, setCanScrollRight]);

    useEffect(() => {
      setScrollable();
      const observer = new MutationObserver(setScrollable);
      if (scrollContainerRef.current) {
        observer.observe(scrollContainerRef.current, {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        });
      }

      const handleScroll = () => setScrollable();
      const currentScrollContainer = scrollContainerRef.current;
      currentScrollContainer?.addEventListener("scroll", handleScroll, { passive: true });
      window.addEventListener("resize", setScrollable);

      return () => {
        observer.disconnect();
        currentScrollContainer?.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", setScrollable);
      };
    }, [setScrollable]);

    return (
      <div className={"scrollContainer"}>
        <ul ref={scrollContainerRef} className={`scrollContainerMain ${className}`}>
          {children}
        </ul>
      </div>
    );
  },
);

ScrollContainer.displayName = "ScrollContainer";

export default ScrollContainer;
