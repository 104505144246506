"use client";

import style from "./style.module.scss";

// ------------引入swiper------------
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore } from "swiper/types";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import Prev from "@/assets/icons/prev.svg";
import Next from "@/assets/icons/next.svg";
import JumpLink from "@/components/JumpLink";
import { useRef } from "react";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25223";

export default function Module({ moduleData }: { moduleData: any }) {
  const swiperRef = useRef<SwiperCore>();

  const initConfig = (data: any) => {
    const config = {
      data: data.dataBatch?.ads || [],
      length: data.dataBatch?.ads?.length || 0,
    };
    return config;
  };
  const PageConfig = initConfig(moduleData);
  if (!PageConfig.data) return null;

  const Img = (props: any) => {
    const { data, index } = props;
    return (
      <div
        key={index}
        onClick={() => sendClickTrack.Home_Pro_Activity({ title: "-100", index: index })}
        exptag={"exp|" + EXPOSURE_RECORD.Home_Pro_Activity_Expo}
        data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
        data-exptag-json-param={JSON.stringify({ title: "-100", index: index })}
      >
        <img src={data.imageUrl} width={data.imageWidth} height={data.imageHeight} style={{ borderRadius: "8px" }} />
      </div>
    );
  };
  return (
    <div className={style["uk-pc-image-ads"]}>
      <div className={style.adsModule}>
        {PageConfig.length === 1 && (
          <JumpLink jumpParams={PageConfig.data[0].jump}>
            <Img data={PageConfig.data[0].img_large} index={0} />
          </JumpLink>
        )}
        {PageConfig.length === 2 &&
          PageConfig.data.map((ad: any, index: any) => (
            <JumpLink jumpParams={ad.jump} key={index}>
              <Img data={ad.img_middle} index={index} />
            </JumpLink>
          ))}
        {PageConfig.length === 3 &&
          PageConfig.data.map((ad: any, index: any) => (
            <JumpLink jumpParams={ad.jump} key={index}>
              <Img data={ad.img_small} index={index} />
            </JumpLink>
          ))}
        {PageConfig.length > 3 && (
          <Swiper
            slidesPerView={3}
            spaceBetween={31}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            modules={[Autoplay]}
            className={style.mySwiper}
          >
            {PageConfig.data.map((ad: any, index: any) => (
              <SwiperSlide key={index}>
                <JumpLink jumpParams={ad.jump}>
                  <Img data={ad.img_small} index={index} />
                </JumpLink>
              </SwiperSlide>
            ))}
            <div className={style.buttonGroup}>
              <button className={style.prev} onClick={() => swiperRef.current?.slidePrev()}>
                <Prev />
              </button>
              <button className={style.next} onClick={() => swiperRef.current?.slideNext()}>
                <Next />
              </button>
            </div>
          </Swiper>
        )}
      </div>
    </div>
  );
}
